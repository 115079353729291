import React from 'react';
import { Input, Typography } from 'antd';
import './index.scss';
import TextArea from 'antd/es/input/TextArea';

const { Text } = Typography;

const CreateRoleStep3: React.FC<any> = ({
  setNameState,
  setDescriptionState
}) => {
  return (
    <div className="createRolesWrapper__createRoleContent3">
      <div className="createRolesWrapper__createRoleInnerContent">
        <Text
          type="secondary"
          strong
          className="createRolesWrapper__createRoleInnerContentText"
        >
          <span className="mandatoryClass">*</span> Enter the role name:
        </Text>
        <Input
          placeholder="Name"
          onChange={(e) => {
            setNameState(e.target.value);
          }}
        />
      </div>
      <div className="createRolesWrapper__createRoleInnerContent">
        <Text
          type="secondary"
          strong
          className="createRolesWrapper__createRoleInnerContentText"
        >
          Enter the description of the role:
        </Text>
        <TextArea
          rows={4}
          placeholder="Description"
          onChange={(e) => {
            setDescriptionState(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default CreateRoleStep3;
