import { ATTRIBUTETYPE, ROLETYPE, dateFormat, EMPTY } from 'types/enums';
import dayjs from 'dayjs';
import { DateRangeFilter, ShiftTiming } from 'types/enums/kpiEnum';
import { checkTimeBeforeShiftEndTime } from 'utils/commonFunction';

const initialState: any = {
    root: {
        showLoader: false,
        sideNavMenuList: [],
        bulkUploadLoader: false,
        allowedMenuList: [],
    },
    userManagement: {
        users: {
            loggedInUserDetails: {},
            loggedInUserScreenPermissionList: [],
            usersList: [],
            userListLoading: false,
            userDetails: {},
            userPreferences: {},
            editUser: false,
            isUserEdited: false,
            editPreferences: false,
            countDetails: {},
            allUsersList: [],
        },
        groups: {
            allGroupsData: [],
            groupsList: {},
            groupListLoading: false,
            isCreated: false,
            isCreatedResponse: {},
            isEdited: false,
            isDeactivated: false,
            dataByGroupId: {},
            resourceType: [],
            resourceTypeDataById: [],
            resourceSubItemsResponse: null,
        },

        roles: {
            editRoleState: ROLETYPE.view,
            roleData: {},
            rolesList: {},
            rolesListLoading: false,
            roleDetails: {},
            createRoleState: false,
            allRolesList: [],
            updateRole: false,
            resourceTypeSubItemsList: [] as any,
            resourceTypeDetails: {},
            resourcePermissionListPayload: [] as any,
            usersRoleListPayload: [] as any,
        },
    },
    configure: {
        attributes: {
            attributeState: ATTRIBUTETYPE.display,
            attributesList: [],
            attributesListLoading: false,
            attributesDetailLoading: false,
            attributeDetails: {},
            industryList: [],
            categoryList: [],
            valueTypeList: [],
            dataReferenceList: [],
            propertyList: [],
            uomList: [],
            uomClassList: [],
            uomMetricList: [],
            createUomClass: false,
            createCategory: false,
            createIndustry: false,
            createUom: false,
            createAttribute: false,
            updateAttribute: false,
            statusUpdateAttribute: false,
        },
        attributesFieldsValues : {
            name: '',
            properties: null,
            category: null,
            uom: null,
            valueType: null,
            dataReference: null,
            displayDigit: '2',
            industry: [],
            description: '',
        }
    },
    login: {
        loginParameters: '',
        isLoggedIn: Boolean(localStorage.getItem('authToken')),
        refreshToken: '',
        rememberMe: false,
        otpData: '',
        otpVerify: '',
        passwordUpdated: false,
        logout: '',
        isEmailValid: true,
        isPasswordValid: true,
        changePassword: false,
        passwordResetLinkSent: false,
        passwordResetLinkValidated: false,
        setNewpasswordLoading: false,
        userEmail: EMPTY?.string,
        recoveryToken: EMPTY?.string,
        recoveryLinkSentLoading: false,
    },
    deviceManagement: {
        blas: {
            addBlaState: 'view',
            blaId: null,
            deviceId: null,
            blasList: [],
            allBlasList: [],
            blasListLoading: false,
            deviceInBlaLoader: false,
            blaDetails: {},
            isDeactivated: false,
            createBlaState: false,
            isEdited: false,
            devicesInBlaList: [],
            deviceIsRemoved: false,
            blaStatusChanged: false,
            selectedDevice: null,
            lastCreatedBla: null,
            sidebarNavigate: false,
        },
        devices: {
            addDeviceState: false,

            deviceDetails: {},
            deviceList: [],
            isEdited: false,
            allDevicesList: [],
            selectedBla: [],
            deviceStart: false,
            deviceStop: false,
            devicesListLoading: false,
            deviceStatusChanged: false,
            deviceStatusResponse: false,
            allDevicesListByBla: [],
            lastAddedDevice: null,
            isCloned: false,
            dataTypes: [],
        },
        tags: {
            createTagState: false,
            tagsList: [],
            allTagsList: [],
            tagListByDeviceId: [],
            tagProperties: [],
            tagsListLoading: false,
            dataTypes: [],
            isEdited: false,
            allTagsByDeviceId: [],
            tagStatusChanged: false,
            tagStatusResponse: false,
            tagDetails: {},
            uploadedTags: false,
        },
    },
    nocilDashboard: {
        filterValues: {
            startTime: checkTimeBeforeShiftEndTime(7)
                ? dayjs()
                      .subtract(1, 'days')
                      .format(
                          `${dateFormat.formatWithoutTime} ${ShiftTiming.shiftAStartTime}`
                      )
                : dayjs().format(
                      `${dateFormat.formatWithoutTime} ${ShiftTiming.shiftAStartTime}`
                  ),
            endTime: dayjs().format(`${dateFormat.format}`),
            dateRange: DateRangeFilter?.Today,
        },
        utilization: {
            loader: false,
            data: {},
        },
        dataVisualization: {
            modelList: [],
            nodeList: [],
            streamTagList: [],
            aggregatedChartData: [],
            aggregatedChartDataLoading: false,
            dataTableList: {
                loader: false,
                data: [],
            },
        },
        currentValues: {
            loader: false,
            data: [],
        },
        kpiMetaValues: {
            loader: false,
            data: [],
        },
        aggregateValues: {
            loader: false,
            data: [],
        },
        powerConsumptionValues: {
            loader: false,
            data: [],
        },
        economiserAndAphvalues: {
            loader: false,
            data: [],
        },
        avgOxygenValues: {
            loader: false,
            data: [],
        },
        fuelRatioValues: {
            loader: false,
            data: [],
        },
        chooseParamValues: {
            loader: false,
            data: [],
        },
        steamRateData: {
            loader: false,
            data: {},
        },
        feedWaterTrend: {
            loader: false,
            data: {},
        },
        steamTempTrend: { loader: false, data: [] },
        steamPressure: { loader: false, data: [] },
        parameters: [],
        parameterTrendData: { loader: false, data: [] },
        coalIntakeProd: { loader: false, data: [] },
        reductionValues: {
            loader: false,
            data: [],
        },
        kpiTrend: {},
        kpiTrendParameter: {
            view: false,
            kpiId: '',
            heading: '',
            subheading: '',
            unit: {},
        },
        steamFlowRate: { loader: false, data: [] },
        isAssumptionAndFormulaOpen: false,
    },
    manualEntry: {
        allManualEntryParams: {
            loader: false,
            data: [],
        },
        manualEntryValueParams: {
            loader: false,
            data: [],
        },
        setManualEntryValueParams: {
            loader: false,
            data: [],
        },
    },
    operatorEntry: {
        allOperatorEntryParams: {
            loader: false,
            data: [],
        },
        setOperatorEntryValueParams: {
            loader: false,
            data: [],
        },
    },
    dataVisualization: {
        modelList: [],
        nodeList: [],
        streamTagList: [],
        aggregatedChartData: [],
        aggregatedChartDataLoading: false,
    },
    calendarConfigurator: {
        timeZonesList: [],
        dayConfigDataAdded: false,
        shiftConfigDataAdded: false,
        assetDetailsAdded: false,
        dayConfigDataByAssetId: [],
        shiftConfigDataByAssetId: [],
        assetDetails: [],
        monthsList: [],
        weekDaysList: [],
        updateShiftDataForApi: [],
        updateDaytDataForApi: [],
        updateModalState: [],
        isSwitchChecked: false,
        isConfigChange: false,
        isDefaultConfigType: false,
        loadingDay: false,
        loadingShift: false,
    },
    dataExplorer: {
        dashboardReporting: {
            reportingList: [],
            createDashboardUrl: false,
            updateDashboardUrl: false,
            deleteDashboardUrl: false,
        },
    },
};

export default initialState;
