export const GET_USERS_LIST = 'GET_USERS_LIST';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FAILURE = 'GET_USERS_LIST_FAILURE';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const ACTIVATE_DEACTIVATE_USER = 'ACTIVATE_DEACTIVATE_USER';
export const ACTIVATE_DEACTIVATE_USER_SUCCESS = 'ACTIVATE_DEACTIVATE_USER_SUCCESS';
export const COUNT_ANALYTICS = 'COUNT_ANALYTICS';
export const COUNT_ANALYTICS_SUCCESS = 'COUNT_ANALYTICS_SUCCESS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';

// roles
export const SET_EDIT_ROLE_STATE = 'SET_EDIT_ROLE_STATE';
export const SET_ROLE_DATA = 'SET_ROLE_DATA';
export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const GET_ROLES_LIST = 'GET_ROLES_LIST';
export const GET_ROLES_LIST_SUCCESS = 'GET_ROLES_LIST_SUCCESS';
export const GET_ROLES_LIST_FAILURE = 'GET_ROLES_LIST_FAILURE';
export const GET_ROLE_DETAILS = 'GET_ROLE_DETAILS';
export const GET_ROLE_DETAIL_SUCCESS = 'GET_ROLE_DETAIL_SUCCESS';
export const SET_ROLE_LIST = 'SET_ROLE_LIST';
export const SET_ROLE_LIST_SUCCESS = 'SET_ROLE_LIST_SUCCESS';
export const UPDATE_ROLE_LIST = 'UPDATE_ROLE_LIST';
export const UPDATE_ROLE_LIST_SUCCESS = 'UPDATE_ROLE_LIST_SUCCESS';
export const ACTIVATE_DEACTIVATE_ROLE = 'ACTIVATE_DEACTIVATE_ROLE';
export const ACTIVATE_DEACTIVATE_ROLE_SUCCESS = 'ACTIVATE_DEACTIVATE_ROLE_SUCCESS';
export const SET_USERS_ROLE_PAYLOAD = 'SET_USERS_ROLE_PAYLOAD';

// resource-groups
export const SET_RESOURCE_TYPE_SUBITEMS = 'SET_RESOURCE_TYPE_SUBITEMS';
export const REMOVE_RESOURCE_TYPE_SUBITEMS = 'REMOVE_RESOURCE_TYPE_SUBITEMS';
export const SET_RESOURCE_TYPE_PAYLOAD = 'SET_RESOURCE_TYPE_PAYLOAD';
export const GET_RESOURCE_TYPE_DETAILS = 'GET_RESOURCE_TYPE_DETAILS';
export const GET_RESOURCE_TYPE_DETAILS_SUCCESS =
    'GET_RESOURCE_TYPE_DETAILS_SUCCESS';

// user-details
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_LOGIN_USER_DETAILS = 'GET_LOGIN_USER_DETAILS';
export const GET_LOGIN_USER_DETAILS_SUCCESS = 'GET_LOGIN_USER_DETAILS_SUCCESS';
export const GET_LOGIN_USER_PERMISSION_LIST = 'GET_LOGIN_USER_PERMISSION_LIST';

// groups
export const GET_GROUPS_LIST = 'GET_GROUPS_LIST';
export const GET_GROUPS_LIST_SUCCESS = 'GET_GROUPS_LIST_SUCCESS';
export const GET_GROUPS_LIST_FAILURE = 'GET_GROUPS_LIST_FAILURE';

export const ADD_GROUPS = 'ADD_GROUPS';
export const ADD_GROUPS_SUCCESS = 'ADD_GROUPS_SUCCESS';
export const EDIT_GROUP = 'EDIT_GROUP';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_FAILURE = 'EDIT_GROUP_FAILURE';
export const REMOVE_EDIT_GROUP = 'REMOVE_EDIT_GROUP';
export const DEACTIVATE_GROUP = 'DEACTIVATE_GROUP';
export const DEACTIVATE_GROUP_SUCCESS = 'DEACTIVATE_GROUP_SUCCESS';
export const GET_GROUP_BY_GROUPID = 'GET_GROUP_BY_GROUPID';
export const GET_GROUP_BY_GROUPID_SUCCESS = 'GET_GROUP_BY_GROUPID_SUCCESS';
export const GET_RESOURCE_TYPE = 'GET_RESOURCE_TYPE';
export const GET_RESOURCE_TYPE_SUCCESS = 'GET_RESOURCE_TYPE_SUCCESS';
export const GET_RESOURCEBYTYPE_BY_RESOURCEID =
    'GET_RESOURCEBYTYPE_BY_RESOURCEID';
export const GET_RESOURCEBYTYPE_BY_RESOURCEID_SUCCESS =
    'GET_RESOURCEBYTYPE_BY_RESOURCEID_SUCCESS';
export const GET_ALL_GROUPS = 'GET_ALL_GROUPS';
export const GET_ALL_GROUPS_SUCCESS = 'GET_ALL_GROUPS_SUCCESS';

// user-preferences
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS';
export const GET_USER_PREFERENCES_LIST = 'GET_USER_PREFERENCES_LIST';
export const GET_USER_PREFERENCES_LIST_SUCCESS =
    'GET_USER_PREFERENCES_LIST_SUCCESS';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';
export const UPDATE_USER_PREFERENCES_SUCCESS =
    'UPDATE_USER_PREFERENCES_SUCCESS';

export const SHOW_LOADER_SUCCESS = 'SHOW_LOADER_SUCCESS';
