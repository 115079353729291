import React, { useEffect, useState } from 'react';
import './index.scss';
import {
    Row,
    Col,
    Drawer,
    Button,
    Divider,
    Input,
    // Dropdown,
    // Space,
    Switch,
    Select,
    Form,
    // type MenuProps,
} from 'antd';
import {
    CloseOutlined,
    // DownOutlined
} from '@ant-design/icons';
import CustomButton from 'components/common/CustomButton';
import { BUTTONTYPE, ROLETYPE } from 'types/enums';
import { useDispatch, useSelector } from 'react-redux';
import {
    createTag,
    getTagProperties,
    getDatatypes,
    editTags,
    removeCreatedTagStatus,
    activateDeactivateTags,
    getTagDetails,
} from 'redux/actions/DeviceManagementActions/tagAction';
import { parseJwt } from 'utils/jwtTokenFunction';
import { handleKeyInput, useCharacterLimit } from 'utils/commonFunction';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { ReactComponent as DeactivateIcon } from 'assets/icons/blaDeactivateIcon.svg';
import { ReactComponent as ActivateIcon } from 'assets/icons/blaActivateIcon.svg';
import { maxInputLength, maxTagNameLength } from 'utils/constants';

const AddTagDrawer: React.FC<any> = ({
    open,
    setAddDrawerState,
    record,
    deviceId,
    type,
    setRecord,
    allDevicesList,
    deviceDetails,
    setSuccessModalState,
    successModalState,
}) => {
    const details = parseJwt();
    const dispatch = useDispatch();
    const { showInputError } = useCharacterLimit();
    const isDisabled = record && Object.keys(record).length > 0;
    const tagStatusChanged = useSelector(
        (state: any) => state.deviceManagement.tags.tagStatusChanged
    );
    const [confirmModalState, setConfirmModalState] = useState<String | any>(
        null
    );

    useEffect(() => {
        open === ROLETYPE.edit &&
            !successModalState &&
            dispatch(getTagDetails(record?.timeSeriesId));
    }, [open, successModalState]);

    useEffect(() => {
        setPayload({
            ...payload,
            deviceId: deviceId,
        });
    }, [deviceId]);

    const tagStatus = useSelector(
        (state: any) => state.deviceManagement.tags.tagDetails
    );

    const [payload, setPayload] = useState({
        deviceId: deviceId ?? 0,
        tagId: '',
        requestedBy: parseJwt().username,
        displayName: '',
        description: '',
        engineeringUnit: '',
        uniqueName: '',
        alias: '',
        dataType: '',
        pollingInterval: '',
        additionalProperties: '',
        timeSeriesId: record?.timeSeriesId,
    });
    const { tagId, displayName, uniqueName } = payload;

    const dataTypes = useSelector(
        (state: any) => state?.deviceManagement?.tags?.dataTypes
    );
    const dataTypeItems = dataTypes.map((dataType: any, index: any) => ({
        label: dataType,
        key: index.toString(),
    }));

    const selectDevice = (key: number): void => {
        dispatch(getDatatypes({ deviceTypeId: key }));
    };
    useEffect(() => {
        deviceDetails?.communicationInterfaceId &&
            dispatch(
                getDatatypes({
                    deviceTypeId: deviceDetails?.communicationInterfaceId,
                })
            );
    }, [deviceDetails]);

    const { Option } = Select;
    const createTagState = useSelector(
        (state: any) => state.deviceManagement?.tags?.createTagState
    );

    const tagPropertiesData = useSelector(
        (state: any) => state.deviceManagement?.tags?.tagProperties
    );

    const tagPropertiesDataItems = tagPropertiesData?.map(
        (item: any, index: any) => ({
            label: item,
            key: index.toString(),
        })
    );
    useEffect(() => {
        if (tagStatusChanged && confirmModalState) {
            setSuccessModalState(`${confirmModalState}`);
        }
        setConfirmModalState(null);
    }, [tagStatusChanged]);

    useEffect(() => {
        dispatch(getTagProperties());
    }, []);
    useEffect(() => {
        dispatch(removeCreatedTagStatus());
    }, [createTagState]);

    const onOkHandler = (values: any): any => {
        if (type === ROLETYPE.add) {
            dispatch(createTag(payload));
        } else if (record) {
            dispatch(
                editTags({
                    ...payload,
                    displayName: values.tagName,
                    requestedBy: details.username,
                    timeSeriesId: record?.timeSeriesId,
                    uniqueName: values.address,
                    dataType: values.dataType,
                    additionalProperties: values.property,
                    pollingInterval: values.pollingInterval,
                })
            );
            setAddDrawerState(null);
            record && setRecord?.('');
        } else {
            dispatch(createTag(payload));
        }
    };

    useEffect(() => {
        createTagState && setAddDrawerState(null);
    }, [createTagState]);

    const initialValues = {
        tagName: record?.name || '',
        tagID: record?.tagId || '',
        address: record?.address || '',
        property: record?.additionalProperties || '',
        device: record?.deviceName || '',
        dataType: record?.dataType || '',
        pollingInterval: record?.pollingInterval || '',
        units: record?.engineeringUnit || '',
    };
    // const items: MenuProps['items'] = [
    //     {
    //         label: 'Option 1',

    //         key: '1',
    //     },

    //     {
    //         label: 'Option 2',

    //         key: '2',
    //     },

    //     {
    //         label: 'Option 3',

    //         key: '3',
    //     },

    //     {
    //         label: 'Option 4',

    //         key: '4',
    //     },
    // ];

    // const menuProps = {
    //     items,
    // };

    return (
        <>
            <Drawer
                className="addTagDrawer"
                placement="right"
                getContainer={false}
                size="large"
                closable={false}
                open={open}
                destroyOnClose={true}
            >
                <>
                    <div>
                        <Row className="addTagDrawer__headerRow">
                            <Col span={16}>
                                <Row>
                                    <Col
                                        span={24}
                                        className="addTagDrawer__heading fw-1000 fs-14"
                                    >
                                        <span>
                                            {record && setRecord
                                                ? 'Edit Tag'
                                                : 'Add Tag'}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                            {open === ROLETYPE.edit ? (
                                <Col span={6} className="addTagDrawer__switch">
                                    <span>
                                        {tagStatus?.isActive
                                            ? 'Active'
                                            : 'Inactive'}
                                    </span>
                                    <Switch
                                        size="small"
                                        checked={tagStatus?.isActive}
                                        onChange={() => {
                                            setConfirmModalState(
                                                tagStatus?.isActive
                                                    ? 'deactivate'
                                                    : 'activate'
                                            );
                                        }}
                                    />
                                </Col>
                            ) : (
                                <Col span={6}></Col>
                            )}
                            <Col span={2}>
                                <Button
                                    className="addTagDrawer__icon"
                                    type="text"
                                    onClick={() => {
                                        setAddDrawerState(null);
                                        record && setRecord?.('');
                                    }}
                                    icon={<CloseOutlined />}
                                ></Button>
                            </Col>
                        </Row>
                        <Divider className="addTagDrawer__divider" />
                        <Form
                            layout="vertical"
                            initialValues={initialValues}
                            onFinish={onOkHandler}
                        >
                            <Row className="addTagDrawer__footerButtons">
                                <Col
                                    span={12}
                                    className="addTagDrawer__formSectionLeft"
                                >
                                    <Form.Item
                                        label="Tag Name"
                                        name="tagName"
                                        className="addTagDrawer__formItem"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Tag Name is required',
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="addTagDrawer__input"
                                            value={displayName}
                                            onChange={(e) => {
                                                setPayload({
                                                    ...payload,
                                                    displayName: e.target.value,
                                                });
                                            }}
                                            onKeyPress={(e) => {
                                                if (
                                                    payload?.displayName
                                                        ?.length ===
                                                    maxTagNameLength
                                                ) {
                                                    showInputError(
                                                        maxTagNameLength
                                                    );
                                                }
                                                handleKeyInput(e, displayName);
                                            }}
                                            maxLength={maxTagNameLength}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    span={12}
                                    className="addTagDrawer__formSectionRight"
                                >
                                    <Form.Item
                                        label="Tag ID"
                                        name="tagID"
                                        className="addTagDrawer__formItem"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Tag ID is required',
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="addTagDrawer__input"
                                            value={tagId}
                                            onChange={(e) => {
                                                setPayload({
                                                    ...payload,
                                                    tagId: e.target.value,
                                                });
                                            }}
                                            onKeyPress={() => {
                                                if (
                                                    payload?.tagId?.length ===
                                                    maxInputLength
                                                ) {
                                                    showInputError(
                                                        maxInputLength
                                                    );
                                                }
                                            }}
                                            disabled={
                                                type === ROLETYPE.add
                                                    ? false
                                                    : isDisabled
                                            }
                                            maxLength={maxInputLength}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="addTagDrawer__form">
                                <Col
                                    span={12}
                                    className="addTagDrawer__formSectionLeft"
                                >
                                    <Form.Item
                                        label="Device"
                                        name="device"
                                        className="addTagDrawer__formItem"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Device is required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            className="addTagDrawer__select"
                                            placeholder="Select"
                                            onSelect={(value, option) => {
                                                selectDevice(
                                                    option?.communicationId
                                                );
                                                setPayload({
                                                    ...payload,
                                                    deviceId: value,
                                                });
                                            }}
                                            disabled={
                                                isDisabled ||
                                                type === ROLETYPE.add
                                            }
                                            defaultValue={record?.deviceName}
                                            notFoundContent="No active device"
                                        >
                                            {allDevicesList?.map(
                                                (item: any) => {
                                                    return (
                                                        item?.isActive && (
                                                            <Option
                                                                value={
                                                                    item?.deviceId
                                                                }
                                                                key={
                                                                    item?.deviceId
                                                                }
                                                                communicationId={
                                                                    item?.communicationInterfaceId
                                                                }
                                                            >
                                                                {
                                                                    item?.deviceName
                                                                }
                                                            </Option>
                                                        )
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col
                                    span={12}
                                    className="addTagDrawer__formSectionRight"
                                >
                                    <Form.Item
                                        label="Data Type"
                                        name="dataType"
                                        className="addTagDrawer__formItem"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Data Type is required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            className="addTagDrawer__select"
                                            placeholder="Select"
                                            onChange={(value, option) => {
                                                setPayload({
                                                    ...payload,
                                                    dataType: value,
                                                });
                                            }}
                                        >
                                            {dataTypeItems?.map((item: any) => {
                                                return (
                                                    <Option
                                                        value={item?.label}
                                                        key={item?.key}
                                                    >
                                                        {item?.label}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="addTagDrawer__form">
                                <Col
                                    span={12}
                                    className="addTagDrawer__formSectionLeft"
                                >
                                    <Form.Item
                                        label="Address"
                                        name="address"
                                        className="addTagDrawer__formItem"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Address is required',
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="addTagDrawer__input"
                                            value={uniqueName}
                                            onChange={(e) => {
                                                setPayload({
                                                    ...payload,
                                                    uniqueName: e.target.value,
                                                });
                                            }}
                                            onKeyPress={() => {
                                                if (
                                                    payload?.uniqueName
                                                        ?.length ===
                                                    maxInputLength
                                                ) {
                                                    showInputError(
                                                        maxInputLength
                                                    );
                                                }
                                            }}
                                            maxLength={maxInputLength}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    span={12}
                                    className="addTagDrawer__formSectionRight"
                                >
                                    <Form.Item
                                        label="Property"
                                        name="property"
                                        className="addTagDrawer__formItem"
                                    >
                                        <Select
                                            className="addTagDrawer__select"
                                            placeholder="Select"
                                            onChange={(value, option) => {
                                                setPayload({
                                                    ...payload,
                                                    additionalProperties: value,
                                                });
                                            }}
                                        >
                                            {tagPropertiesDataItems?.map(
                                                (item: any) => {
                                                    return (
                                                        <Option
                                                            value={item.label}
                                                            key={item.key}
                                                        >
                                                            {item.label}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/*  <Row className="addTagDrawer__form">
                               { will work on this later} 
                                 <Col
                                    span={12}
                                    className="addTagDrawer__formSectionRight"
                                >
                                    <Form.Item
                                        label="Units"
                                        name="units"
                                        className="addTagDrawer__formItem"
                                    >
                                        <Dropdown
                                            className="addTagDrawer__input"
                                            trigger={['click']}
                                            menu={menuProps}
                                        >
                                            <Button>
                                                <Space>
                                                    <span>Select</span>
                                                    {'  '}
                                                    <DownOutlined />
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    </Form.Item>
                                </Col> 
                                 <Col
                                    span={12}
                                    className="addTagDrawer__formSectionRight"
                                >
                                    <Form.Item
                                        label="Polling Interval"
                                        name="pollingInterval"
                                        className="addTagDrawer__formItem"
                                    >
                                        <Dropdown
                                            className="addTagDrawer__input"
                                            trigger={['click']}
                                            menu={menuProps}
                                        >
                                            <Button>
                                                <Space>
                                                    <span>Select</span>
                                                    {'  '}
                                                    <DownOutlined />
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    </Form.Item>
                                </Col> 
                            </Row> */}
                            <div className="addTagDrawer__button">
                                <Divider />
                                <Row className="addTagDrawer__footerButtons">
                                    <Col
                                        span={4}
                                        className="addTagDrawer__cancelButton"
                                    >
                                        <CustomButton
                                            type={BUTTONTYPE.cancel}
                                            disabled={false}
                                            handleClick={() => {
                                                setAddDrawerState(null);
                                                record && setRecord?.('');
                                            }}
                                        />
                                    </Col>
                                    <Col
                                        span={4}
                                        className="addTagDrawer__saveButton"
                                    >
                                        <CustomButton
                                            type={BUTTONTYPE.save}
                                            disabled={
                                                !(
                                                    payload.tagId &&
                                                    payload.displayName &&
                                                    payload.dataType &&
                                                    payload.uniqueName
                                                ) && !record
                                            }
                                            typeOfButton={'submit'}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </div>
                </>
            </Drawer>
            <ConfirmationModal
                open={confirmModalState}
                icon={
                    confirmModalState === 'activate' ? (
                        <ActivateIcon />
                    ) : (
                        <DeactivateIcon />
                    )
                }
                onOk={() => {
                    if (confirmModalState === 'activate') {
                        dispatch(
                            activateDeactivateTags({
                                id: [parseInt(record?.timeSeriesId)],
                                active: true,
                                updatedBy: details.username,
                            })
                        );
                    } else if (confirmModalState === 'deactivate') {
                        dispatch(
                            activateDeactivateTags({
                                id: [parseInt(record?.timeSeriesId)],
                                active: false,
                                updatedBy: details.username,
                            })
                        );
                    }
                }}
                onCancel={() => {
                    setConfirmModalState(null);
                }}
                text={`Are you sure you want to ${confirmModalState} selected tag?`}
            />
        </>
    );
};

export default AddTagDrawer;
