// reports

export const GET_REPORTING_LIST = "GET_REPORTING_LIST";
export const GET_REPORTING_LIST_SUCCESS = "GET_REPORTING_LIST_SUCCESS";
export const GET_REPORTING_LIST_FAILURE = "GET_REPORTING_LIST_FAILURE";

// for Add dashboard url

export const ADD_DASHBOARD_URL = "ADD_DASHBOARD_URL";
export const ADD_DASHBOARD_URL_SUCCESS = "ADD_DASHBOARD_URL_SUCCESS";
export const ADD_DASHBOARD_URL_FAILURE = "ADD_DASHBOARD_URL_FAILURE";

// for Edit dashboard url

export const UPDATE_DASHBOARD_URL = "UPDATE_DASHBOARD_URL ";
export const UPDATE_DASHBOARD_URL_SUCCESS = "UPDATE_DASHBOARD_URL_SUCCESS";
export const UPDATE_DASHBOARD_URL_FAILURE = "UPDATE_DASHBOARD_URL_FAILURE";

// for Delete dashboard url

export const DELETE_DASHBOARD_URL = "DELETE_DASHBOARD_URL ";
export const DELETE_DASHBOARD_URL_SUCCESS = "DELETE_DASHBOARD_URL_SUCCESS";
export const DELETE_DASHBOARD_URL_FAILURE = "DELETE_DASHBOARD_URL_FAILURE";
