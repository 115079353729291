export const GET_TIMEZONE_LIST = 'GET_TIMEZONE_LIST';
export const GET_TIMEZONE_LIST_SUCCESS = 'GET_TIMEZONE_LIST_SUCCESS';
export const GET_TIMEZONE_LIST_FAILURE = 'GET_TIMEZONE_LIST_FAILURE';

export const SET_DAY_CONFIG_DETAILS = 'SET_DAY_CONFIG_DETAILS';
export const SET_DAY_CONFIG_DETAILS_SUCCESS = 'SET_DAY_CONFIG_DETAILS_SUCCESS';
export const SET_DAY_CONFIG_DETAILS_FAILURE = 'SET_DAY_CONFIG_DETAILS_FAILURE';

export const SET_SHIFT_CONFIG_DETAILS = 'SET_SHIFT_CONFIG_DETAILS';
export const SET_SHIFT_CONFIG_DETAILS_SUCCESS =
    'SET_SHIFT_CONFIG_DETAILS_SUCCESS';
export const SET_SHIFT_CONFIG_DETAILS_FAILURE =
    'SET_SHIFT_CONFIG_DETAILS_FAILURE';

export const SET_ASSET_DETAILS = 'SET_ASSET_DETAILS';
export const SET_ASSET_DETAILS_SUCCESS = 'SET_ASSET_DETAILS_SUCCESS';
export const SET_ASSET_DETAILS_FAILURE = 'SET_ASSET_DETAILS_FAILURE';
export const ASSET_DETAILS_RESET = 'ASSET_DETAILS_RESET';

export const GET_DAY_CONFIG_BY_ASSET_ID = 'GET_DAY_CONFIG_BY_ASSET_ID';
export const GET_DAY_CONFIG_BY_ASSET_ID_SUCCESS =
    'GET_DAY_CONFIG_BY_ASSET_ID_SUCCESS';
export const GET_DAY_CONFIG_BY_ASSET_ID_FAILURE =
    'GET_DAY_CONFIG_BY_ASSET_ID_FAILURE';

export const GET_SHIFT_CONFIG_BY_ASSET_ID = 'GET_SHIFT_CONFIG_BY_ASSET_ID';
export const GET_SHIFT_CONFIG_BY_ASSET_ID_SUCCESS =
    'GET_SHIFT_CONFIG_BY_ASSET_ID_SUCCESS';
export const GET_SHIFT_CONFIG_BY_ASSET_ID_FAILURE =
    'GET_SHIFT_CONFIG_BY_ASSET_ID_FAILURE';

export const GET_ASSET_DETAILS_BY_ID = 'GET_ASSET_DETAILS_BY_ID';
export const GET_ASSET_DETAILS_BY_ID_SUCCESS =
    'GET_ASSET_DETAILS_BY_ID_SUCCESS';
export const GET_ASSET_DETAILS_BY_ID_FAILURE =
    'GET_ASSET_DETAILS_BY_ID_FAILURE';
export const UPDATE_SHIFT_DETAILS = 'UPDATE_SHIFT_DETAILS';
export const UPDATE_DAY_DETAILS = 'UPDATE_DAY_DETAILS';

export const GET_MONTHS_LIST = 'GET_MONTHS_LIST';
export const GET_MONTHS_LIST_SUCCESS = 'GET_MONTHS_LIST_SUCCESS';
export const GET_MONTHS_LIST_FAILURE = 'GET_MONTHS_LIST_FAILURE';

export const GET_WEEK_DAYS_LIST = 'GET_WEEK_DAYS_LIST';
export const GET_WEEK_DAYS_LIST_SUCCESS = 'GET_WEEK_DAYS_LIST_SUCCESS';
export const GET_WEEK_DAYS_LIST_FAILURE = 'GET_WEEK_DAYS_LIST_FAILURE';

export const SHOW_LOADER_SUCCESS = 'SHOW_LOADER_SUCCESS';
export const GET_UPDATE_API_SHIFT = 'GET_UPDATE_API_SHIFT';
export const GET_UPDATE_API_DAY = 'GET_UPDATE_API_DAY';
export const UPDATE_MODAL = 'UPDATE_MODAL';

export const CLEAR_DAY_DATA = 'CLEAR_DAY_DATA';

export const ENABLE_SWITCH_BUTTON = 'ENABLE_SWITCH_BUTTON';
export const CONFIG_CHANGE_TRUE = 'CONFIG_CHANGE_TRUE';
export const CONFIG_CHANGE_FALSE = 'CONFIG_CHANGE_FALSE';
export const DEFAULT_CONFIG_TYPE = 'DEFAULT_CONFIG_TYPE';
export const DEFAULT_CONFIG_TYPE_FALSE = 'DEFAULT_CONFIG_TYPE_FALSE';
export const SET_LOADING_DAY = 'SET_LOADING_DAY';
export const SET_LOADING_SHIFT = 'SET_LOADING_SHIFT';

export const CLEAR_ASSET_DATA_ON_MODEL_CHANGE =
    'CLEAR_ASSET_DATA_ON_MODEL_CHANGE';
