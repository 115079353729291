export const modalShow = (state: any, setState: any): any => {
  setState(true);
};

export const okHandle = (state: any, setState: any): any => {
  setState(false);
};

export const cancelHandle = (state: any, setState: any): any => {
  setState(false);
};
