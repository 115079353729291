export const GET_SIDENAV_MENU_LIST = 'GET_SIDENAV_MENU_LIST';
export const GET_SIDENAV_MENU_LIST_SUCCESS = 'GET_SIDENAV_MENU_LIST_SUCCESS';

export const GET_ALLOWED_MENU_LIST = 'GET_ALLOWED_MENU_LIST';
export const GET_ALLOWED_MENU_LIST_SUCCESS = 'GET_ALLOWED_MENU_LIST_SUCCESS';

export const SET_EDIT_ROLE_STATE = 'SET_EDIT_ROLE_STATE';
export const SET_ROLE_DATA = 'SET_ROLE_DATA';
// Login Action Type or constants
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const SET_REMEMBER_ME = 'SET_REMEMBER_ME';
// Forgot Password constants
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
