import { Col, Form, Input, Row, Select, message } from 'antd';
import CustomButton from 'components/common/CustomButton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    addUser,
    editUser,
    getUserPreferences,
} from 'redux/actions/UserManagementActions/usersAction';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { EMPTY, PERMISSIONS, resourceName } from 'types/enums';
import { type AddUsermanuallyProps } from 'types/interfaces/PropsInterfaces/UserManagement/usersPropsInterfaces';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { cancelHandle } from 'utils/modalFunction';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmationIcon.svg';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import { getAllRoles } from 'redux/actions/UserManagementActions/rolesAction';
import { getObjectByResourceName, hasPermission } from 'utils/rbacFunction';
import { parseJwt } from 'utils/jwtTokenFunction';

const AddUserManually: React.FC<AddUsermanuallyProps> = ({
    handleCancle,
    data,
    isEdit,
    onOk,
    paginationPayload,
    setadduserTypeValue,
}) => {
    const { Option } = Select;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const loggedInUserId = parseJwt();
    const [showFields, setShowFields] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeSuccessModalOpen, setActiveSuccessModalOpen] = useState(false);
    const [uploadedProfileImage, setUploadedProfileImage] = useState<any>();

    const allRolesList = useSelector(
        (state: any) => state.userManagement?.roles?.allRolesList
    );

    const userPreference = useSelector(
        (state: any) => state.userManagement.users.userPreferences
    );

    const loggedInUserPermissionData = useSelector(
        (state: any) =>
            state.userManagement.users?.loggedInUserScreenPermissionList
    );

    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );

    useEffect(() => {
        setUploadedProfileImage(data?.profileImage);
    }, [data]);

    const loggedInUserPermission = getObjectByResourceName(
        resourceName.roles,
        loggedInUserPermissionData
    );
    useEffect(() => {
        if (
            loggedInUserPermission?.permission?.length ||
            loggedInUserDetails?.admin
        ) {
            dispatch(getAllRoles());
        }
        dispatch(getUserPreferences());
    }, [loggedInUserDetails, loggedInUserPermission]);

    const onFinish = (values: {}): void => {
        setadduserTypeValue(false);
        if (isEdit) {
            dispatch(
                editUser({
                    ...values,
                    userId: data.userId,
                    upload: uploadedProfileImage,
                    paginationPayload: paginationPayload,
                    updatedBy: loggedInUserId.username,
                })
            );
            // will use
            // setPayload({ ...values, userId: data.userId });
            // modalShow(isModalOpen, setIsModalOpen);
            onOk();
        } else {
            dispatch(
                addUser({
                    ...values,
                    upload: uploadedProfileImage,
                    paginationPayload: paginationPayload,
                    createdBy: loggedInUserId.username,
                    updatedBy: loggedInUserId.username,
                })
            );

            onOk();
        }
    };
    // will use later
    // const isEdited = useSelector(
    //   (state: any) => state.userManagement.users.isUserEdited
    // );
    // will use later
    // const isEdited = useSelector(
    //   (state: any) => state.userManagement.users.isUserEdited
    // );

    const onFinishFailed = (errorInfo: any): void => {};
    useEffect(() => {
        if (
            loggedInUserPermission?.permission?.length ||
            loggedInUserDetails?.admin
        ) {
            dispatch(getAllRoles());
        }
    }, [dispatch]);

    // will use later
    const onOkHandler = (): any => {
        // dispatch(editUser({ ...payload }));
        // if (isEdited) {
        //   okHandle(isModalOpen, setIsModalOpen);
        //   modalShow(activeSuccessModalOpen, setActiveSuccessModalOpen);
        //   onOk();
        // }
    };

    const onShowMoreFieldshandler = (): void => {
        setShowFields(true);
    };

    const handleImageUpload = (event: any): any => {
        const file = event.target.files[0];
        if (file.size > 500 * 1024) {
            message.error('Image must be smaller than 500KB!');
            return;
        }
        const reader = new FileReader();
        reader.onloadend = (): any => {
            setUploadedProfileImage(reader?.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <div className="addUserManually">
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        firstName: data?.firstName || '',
                        lastName: data?.lastName || '',
                        workEmailID: data?.email || '',
                        mobileNumber: data?.mobileNo || '',
                        role: data?.roles[0]?.roleId || '',
                        designation: data?.designation || '',
                        reportingTo: data?.reportingTo || '',
                        group: data?.userGroup || '',
                        timeZone: data?.timeZone?.timeZoneId || '',
                        language: data?.language?.languageId || '',
                        metrics: data?.metrics?.metricId || '',
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    {showFields || isEdit ? (
                        <Row>
                            <Col
                                span={24}
                                className="addUserManually__imageUpload fs-14 fw-400"
                            >
                                <Form.Item
                                    className="imageUpload__container"
                                    label="Upload Profile Picture"
                                    valuePropName="fileList"
                                    name={'upload'}
                                >
                                    <label
                                        className={
                                            uploadedProfileImage
                                                ? 'display-none'
                                                : 'custom-file-upload'
                                        }
                                    >
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                        />
                                        <div className="upload-inner">
                                            <PlusOutlined />
                                            <span>Upload</span>
                                        </div>
                                    </label>
                                    {uploadedProfileImage && (
                                        <>
                                            <img
                                                src={uploadedProfileImage}
                                                alt="avatar"
                                                className="image__container"
                                            />
                                            <div
                                                className="remove__container"
                                                onClick={() => {
                                                    setUploadedProfileImage('');
                                                }}
                                            >
                                                <div className="remove">
                                                    <DeleteOutlined />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : (
                        EMPTY.string
                    )}
                    <div className="addUserManually__content__wrapper">
                        <div className="addUserManually__wrapper">
                            <Row gutter={10}>
                                <Col span={12}>
                                    <Form.Item
                                        label="First Name"
                                        name="firstName"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Enter first name containing alphabets only',
                                                pattern: /^[a-zA-Z-]+$/i,
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter first name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Last Name"
                                        name="lastName"
                                        rules={[
                                            {
                                                message:
                                                    'Enter last name containing alphabets only',
                                                pattern: /^[a-zA-Z-]+$/i,
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter last name" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Work Email ID"
                                        name="workEmailID"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Enter email address here',
                                            },
                                        ]}
                                    >
                                        <Input
                                            type="email"
                                            placeholder="Enter email address here"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Mobile Number"
                                        name="mobileNumber"
                                        rules={[
                                            {
                                                max: 13,
                                                message:
                                                    'Mobile Number cannot be more than 13 digits',
                                            },
                                        ]}
                                    >
                                        <Input
                                            type="number"
                                            className="hide-sorting"
                                            placeholder="Enter Mobile Number"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {showFields || isEdit ? (
                                <>
                                    <Row gutter={10}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Role"
                                                name="role"

                                                // rules={[{ required: true, message: 'Select Role' }]}
                                            >
                                                <Select
                                                    placeholder="Select BLA for this device"
                                                    disabled={
                                                        hasPermission(
                                                            getObjectByResourceName(
                                                                resourceName.roles,
                                                                loggedInUserPermissionData
                                                            ),
                                                            PERMISSIONS.read
                                                        )
                                                            ? false
                                                            : !loggedInUserDetails.admin
                                                    }
                                                >
                                                    {allRolesList?.map(
                                                        (item: any) => {
                                                            return (
                                                                <Option
                                                                    value={
                                                                        item?.roleId
                                                                    }
                                                                    key={
                                                                        item?.roleId
                                                                    }
                                                                >
                                                                    {
                                                                        item?.roleName
                                                                    }
                                                                </Option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label="Designation"
                                                name="designation"
                                            >
                                                <Input
                                                    name="designation"
                                                    placeholder="Enter Designation"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={10}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Reporting To"
                                                name="reportingTo"
                                            >
                                                <Select placeholder="Select">
                                                    {/* will implement later */}
                                                    {/* <Option value="1">China</Option>
                          <Option value="2">U.S.A</Option> */}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Time Zone"
                                                name="timeZone"
                                            >
                                                <Select
                                                    placeholder="Select"
                                                    defaultValue={'select'}
                                                >
                                                    {userPreference?.timeZoneList?.map(
                                                        (item: any) => {
                                                            return (
                                                                <Option
                                                                    value={
                                                                        item.timeZoneId
                                                                    }
                                                                    key={
                                                                        item.timeZoneId
                                                                    }
                                                                >
                                                                    {
                                                                        item.timeZone
                                                                    }
                                                                </Option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={10}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Language"
                                                name="language"
                                            >
                                                <Select placeholder="Select">
                                                    {userPreference?.languageList?.map(
                                                        (item: any) => {
                                                            return (
                                                                <Option
                                                                    value={
                                                                        item.languageId
                                                                    }
                                                                    key={
                                                                        item.languageId
                                                                    }
                                                                >
                                                                    {
                                                                        item.languageName
                                                                    }
                                                                </Option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Metric"
                                                name="metrics"
                                            >
                                                <Select placeholder="Select">
                                                    {userPreference?.metricsList?.map(
                                                        (item: any) => {
                                                            return (
                                                                <Option
                                                                    value={
                                                                        item.metricId
                                                                    }
                                                                    key={
                                                                        item.metricId
                                                                    }
                                                                >
                                                                    {
                                                                        item.metricSystem
                                                                    }
                                                                </Option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                EMPTY.string
                            )}
                        </div>
                    </div>
                    <div className="userCreationTypeWrapper__footerWrapper">
                        <div className="userCreationTypeWrapper__footerContent">
                            <CustomButton
                                type={'Cancel'}
                                disabled={false}
                                handleClick={handleCancle}
                            />
                            <CustomButton
                                type={isEdit ? 'Save' : 'Add User'}
                                typeOfButton="submit"
                                disabled={false}
                            />
                            {showFields || isEdit ? (
                                EMPTY.string
                            ) : (
                                <>
                                    <div
                                        className="white-space-nwrap show-field"
                                        onClick={onShowMoreFieldshandler}
                                    >
                                        Show More Fields
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Form>
            </div>
            <ConfirmationModal
                open={isModalOpen}
                onOk={() => onOkHandler()}
                onCancel={() => cancelHandle(isModalOpen, setIsModalOpen)}
                text={`Are you sure you want to save this?`}
                icon={<ConfirmationIcon />}
            />
            <SuccessfulModal
                open={activeSuccessModalOpen}
                onOk={() => onOkHandler()}
                onCancel={() =>
                    cancelHandle(
                        activeSuccessModalOpen,
                        setActiveSuccessModalOpen
                    )
                }
                text={'Saved Successfully'}
            />
        </>
    );
};

export default AddUserManually;
