import React from 'react';
import './index.scss';
import { Row, Col, Drawer, Button, Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as ActiveDotIcon } from 'assets/icons/activeDot.svg';
import { ReactComponent as InActiveDotIcon } from 'assets/icons/inactiveDot.svg';

const ViewTagDrawer: React.FC<any> = ({
    open,
    setViewDrawerState,
    setEditState,
    details,
    setRecord,
}) => {
    return (
        <>
            <Drawer
                className="viewTagDrawer"
                placement="right"
                getContainer={false}
                size="default"
                closable={false}
                open={open}
            >
                <>
                    <div>
                        <Row>
                            <Col span={12}>
                                <Row>
                                    <Col
                                        span={24}
                                        className="viewTagDrawer__heading fw-500 fs-16"
                                    >
                                        <span>{'View Tag'}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={9}>
                                <Button
                                    disabled={!details?.isActive}
                                    className="viewTagDrawer__icon"
                                    onClick={() => {
                                        setViewDrawerState(false);
                                        setEditState('edit');
                                    }}
                                    type="primary"
                                    ghost
                                >
                                    Edit
                                </Button>
                            </Col>
                            <Col span={3}>
                                <Button
                                    className="viewTagDrawer__icon"
                                    type="text"
                                    onClick={() => {
                                        setViewDrawerState(false);
                                        setRecord('');
                                    }}
                                    icon={<CloseOutlined />}
                                ></Button>
                            </Col>
                        </Row>
                        <Divider className="viewTagDrawer__divider" />
                        <Row>
                            <Col span={12}>
                                <Row>
                                    <Col span={24} className="d-flex">
                                        <div className="viewTagDrawer__name fw-400 fs-18">
                                            {details?.name}
                                        </div>
                                        <div className="viewTagDrawer__subName fw-400 fs-14">
                                            Tag ID : {details?.tagId}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                {details?.isActive ? (
                                    <div className="viewTagDrawer__status fw-400 fs-14">
                                        <ActiveDotIcon />
                                        <span>{'Active'}</span>
                                    </div>
                                ) : (
                                    <div className="viewTagDrawer__status fw-400 fs-14">
                                        <InActiveDotIcon />
                                        <span>{'Inactive'}</span>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Divider className="viewTagDrawer__divider" />
                        <Row className="viewTagDrawer__form">
                            <Col
                                span={12}
                                className="viewTagDrawer__formSectionLeft"
                            >
                                <Row>
                                    <Col span={24} className="d-flex">
                                        <div className="viewTagDrawer__name fw-400 fs-14">
                                            {'Data Type'}
                                        </div>
                                        <div className="viewTagDrawer__subName fw-400 fs-14">
                                            {details?.dataType}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                span={12}
                                className="viewTagDrawer__formSectionRight"
                            >
                                <Row>
                                    <Col span={24} className="d-flex">
                                        <div className="viewTagDrawer__name fw-400 fs-14">
                                            {'Address'}
                                        </div>
                                        <div className="viewTagDrawer__subName fw-400 fs-14">
                                            {details?.address}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="viewTagDrawer__form">
                            <Col
                                span={12}
                                className="viewTagDrawer__formSectionLeft"
                            >
                                <Row>
                                    <Col span={24} className="d-flex">
                                        <div className="viewTagDrawer__name fw-400 fs-14">
                                            {'Unit'}
                                        </div>
                                        <div className="viewTagDrawer__subName fw-400 fs-14">
                                            {details?.unit}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                span={12}
                                className="viewTagDrawer__formSectionRight"
                            >
                                <Row>
                                    <Col span={24} className="d-flex">
                                        <div className="viewTagDrawer__name fw-400 fs-14">
                                            {'Property'}
                                        </div>
                                        <div className="viewTagDrawer__subName fw-400 fs-14">
                                            {details?.additionalProperties}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </>
            </Drawer>
        </>
    );
};

export default ViewTagDrawer;
