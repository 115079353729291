export const GET_LOGIN_USER = 'GET_LOGIN_USER';
export const GET_LOGIN_USER_SUCCESS = 'GET_LOGIN_USER_SUCCESS';
// Login Action Type or constants
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const SET_REMEMBER_ME = 'SET_REMEMBER_ME';

// Forgot Password constants
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
// Change Password
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const NEW_PASSWORD = 'NEW_PASSWORD';
// Verify Otp
export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';
export const CLEAR_OTP = 'CLEAR_OTP';
export const RESET_FORGET_EMAIL_VALIDATION = 'RESET_FORGET_EMAIL_VALIDATION';

// setnewpassword
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILED = 'SET_NEW_PASSWORD_FAILED';

// Logout Action
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';
export const SET_PASSWORD_UPDATE = 'SET_PASSWORD_UPDATE';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const SEND_PASSWORD_RESET_LINK = 'SEND_PASSWORD_RESET_LINK';
export const SEND_PASSWORD_RESET_LINK_SUCCESS =
    'SEND_PASSWORD_RESET_LINK_SUCCESS';
export const SEND_PASSWORD_RESET_LINK_FAILURE =
    'SEND_PASSWORD_RESET_LINK_FAILURE';

export const VALIDATE_RECOVERY_TOKEN = 'VALIDATE_RECOVERY_TOKEN';
export const VALIDATE_RECOVERY_TOKEN_SUCCESS =
    'VALIDATE_RECOVERY_TOKEN_SUCCESS';
export const VALIDATE_RECOVERY_TOKEN_FAILURE =
    'VALIDATE_RECOVERY_TOKEN_FAILURE';

export const CLOSE_SET_NEW_PASSWORD_MODAL = 'CLOSE_SET_NEW_PASSWORD_MODAL';

export const SET_IS_EMAIL_VALID = 'SET_IS_EMAIL_VALID';
