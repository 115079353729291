import './index.scss';
import React from 'react';
import { Col, Row, Form, Input, Spin } from 'antd';
import LoginLogo from 'assets/icons/braboFooterLogo.svg';
import ScreenNameHeading from 'components/common/ScreenNameHeading';
import { Link } from 'react-router-dom';
import rightArrow from 'assets/icons/rightarrow.svg';
import CustomButton from 'components/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { sendPasswordResetLink } from 'redux/actions/AuthAction/authAction';
import { BUTTONTYPE } from 'types/enums';

export const ForgetPassword: React.FC<{ setForgotEmail: any }> = ({
    setForgotEmail,
}) => {
    const dispatch = useDispatch();
    const emailIsValid = useSelector((state: any) => state.login.isEmailValid);

    const forgotPasswordHandler = (value: any): void => {
        setForgotEmail(value);
        dispatch(sendPasswordResetLink(value.workEmail));
    };
    const recoveryLinkSentLoading = useSelector(
        (state: any) => state?.login?.recoveryLinkSentLoading
    );

    return (
        <>
            {recoveryLinkSentLoading ? (
                <div className="setNewPassword__loader">
                    <Spin />
                </div>
            ) : (
                <div className="login forgotPassword">
                    <Row>
                        <Col span={12} className="login__bgHeight">
                            <div className="login__leftSection"></div>
                        </Col>
                        <Col span={12}>
                            <div className="login__rightSection">
                                <div className="logo text-center">
                                    <img src={LoginLogo} alt="icon" />
                                </div>
                                <div className="title">
                                    <div className="right-arrow">
                                        <Link
                                            to="/login"
                                            className="fs-16 fw-500"
                                        >
                                            <img
                                                src={rightArrow}
                                                alt="right-arrow-svg"
                                            />
                                            Back to Sign in
                                        </Link>
                                    </div>
                                    <ScreenNameHeading
                                        heading="Forgot Password?"
                                        subHeading="Please enter your work email to reset your password."
                                    />
                                </div>

                                <Form
                                    name="login_form"
                                    layout="vertical"
                                    onFinish={forgotPasswordHandler}
                                >
                                    <Form.Item
                                        name="workEmail"
                                        label="Work Email"
                                        className="forgot-pass"
                                    >
                                        <Input
                                            placeholder="Type your work Email"
                                            status={
                                                emailIsValid === false
                                                    ? 'error'
                                                    : ''
                                            }
                                        />
                                    </Form.Item>
                                    {emailIsValid === false ? (
                                        <span className="error-message fs-16">
                                            There is no account linked to this
                                            email
                                        </span>
                                    ) : null}

                                    <div className=" login-btn ">
                                        <CustomButton
                                            type={BUTTONTYPE?.sendRecoveryMail}
                                            disabled={false}
                                            typeOfButton={'submit'}
                                        />
                                    </div>
                                    <span className="fs-16 fw-400 copyright-text">
                                        Powered by Solulever
                                    </span>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};
