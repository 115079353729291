import {
    Button,
    Collapse,
    Input,
    message,
    Select,
    Switch,
    Tooltip,
} from 'antd';
import { useCallback, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import './createopcform.scss';
import { addDevice } from 'redux/actions/DeviceManagementActions/deviceAction';
import { useDispatch, useSelector } from 'react-redux';
import { type CreatePLCDevice } from 'types/interfaces/PropsInterfaces/DeviceManagement/devices.interface';
import { deviceType } from 'types/enums';
import {
    debounceError,
    handleKeyInput,
    useCharacterLimit,
} from 'utils/commonFunction';
import { maxDeviceNameLength, maxInputLength } from 'utils/constants';

export const CreatePLCForm: any = (props: any) => {
    const dispatch = useDispatch();

    const emptyForm: CreatePLCDevice = {
        plcModel: '',
        deviceName: '',
        description: '',
        location: '',
        readOnly: true,
        deviceType: 'Industrial Controller',
        hostName: '',
        port: '',
        cpuSlotNumber: '',
        connectionTimeout: '',
        userName: '',
        password: '',
        parentLevel: [],
        filterTags: [],
        scanRate: '',
        requestTimeout: '',
        deviceId: '',
        attemptsBeforeTimeout: '',
    };

    const [createPLCDevice, setPLCDevice] =
        useState<CreatePLCDevice>(emptyForm);
    const { Panel } = Collapse;

    const removeForm = (): any => {
        setPLCDevice(emptyForm);
        props.setShowDrawer(false);
    };
    const blaIdDevices = useSelector(
        (state: any) => state.deviceManagement.devices?.selectedBla[0]?.blaId
    );
    const blaId = useSelector(
        (state: any) => state.deviceManagement.blas.blaId
    );

    const selectedBlaBlasTab = useSelector(
        (state: any) =>
            state.deviceManagement.blas.lastCreatedBla?.blaDetails?.data.blaId
    );
    const createNewPLCDevice = (): void => {
        const formObject: CreatePLCDevice = createPLCDevice;

        const payload: any = {};
        // Note: 'All to be integrated' values are to be given
        payload.plcOem = 'To be integrated';
        payload.plcModel = 'To be integrated';
        payload.name = formObject.deviceName;
        payload.description = formObject.description;
        payload.deviceTypeId = deviceType.plcAb;
        payload.blaId =
            selectedBlaBlasTab ||
            blaIdDevices ||
            props?.blaDetails?.blaId ||
            blaId;
        payload.location = formObject.location;
        payload.readOnly = formObject.readOnly;
        payload.deviceAuthentication = {
            username: formObject.userName,
            password: formObject.password,
        };
        payload.plcAbConnectionDetails = {
            ipAddress: formObject.hostName,
            port: formObject.port,
            cpuSlot: formObject.cpuSlotNumber,
        };
        payload.scanRate = formObject.scanRate;
        payload.requestTimeout = formObject.requestTimeout;
        payload.attemptsBeforeTimeout = formObject.attemptsBeforeTimeout;
        payload.requestedBy = 'To be integrated';
        dispatch(addDevice(payload));
    };
    const showMessageError = useCallback(
        debounceError((errorMsg: string) => {
            message.error(errorMsg);
        }, 100),
        []
    );

    const setPLCNumberProperty = (val: any, property: any): void => {
        const pattern = /^[0-9]*$/;
        if (!pattern.test(val)) {
            showMessageError('Please enter a valid number');
        } else if (val.length > 8) {
            return undefined;
        } else {
            setPLCDevice({ ...createPLCDevice, [property]: val });
        }
    };
    const { showInputError } = useCharacterLimit();

    return (
        <>
            <div className="form-container">
                <p className="form-heading">
                    New Device - Industrial Controller
                </p>
                <div className="plc-dropdown">
                    <div className="title">
                        <label className="label">PLC OEM</label>
                        <Tooltip
                            className="tooltip"
                            title="Select the PLC Model from the list depending upon the type of interface you have connected the PLC device to the platform"
                        >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </div>
                    <Select
                        placeholder="Select your PLC OEM"
                        className="opc-form-select"
                    ></Select>
                </div>
                <div className="plc-dropdown-modal">
                    <div className="title">
                        <label className="label">PLC Model</label>
                        <Tooltip
                            className="tooltip"
                            title="Select the PLC Model from the list depending upon the type of interface you have connected the PLC device to the platform"
                        >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </div>
                    <Select
                        placeholder="​Select your PLC Device Model"
                        className="opc-form-select"
                    ></Select>
                </div>
                <Collapse defaultActiveKey={['1', '2', '3']}>
                    <Panel header={'Device Information'} key="1">
                        <div className="form-row">
                            <label>Device Name</label>
                            <Input
                                value={createPLCDevice.deviceName}
                                onChange={(e) => {
                                    setPLCDevice({
                                        ...createPLCDevice,
                                        deviceName: e.target.value,
                                    });
                                }}
                                className="input"
                                placeholder="Enter Device Name"
                                onKeyPress={(e) => {
                                    if (
                                        createPLCDevice?.deviceName?.length ===
                                        maxDeviceNameLength
                                    ) {
                                        showInputError(maxDeviceNameLength);
                                    }
                                    handleKeyInput(
                                        e,
                                        createPLCDevice?.deviceName
                                    );
                                }}
                                maxLength={maxDeviceNameLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>Description</label>
                            <Input
                                value={createPLCDevice.description}
                                onChange={(e) => {
                                    setPLCDevice({
                                        ...createPLCDevice,
                                        description: e.target.value,
                                    });
                                }}
                                onKeyPress={() => {
                                    if (
                                        createPLCDevice?.description?.length ===
                                        maxInputLength
                                    ) {
                                        showInputError(maxInputLength);
                                    }
                                }}
                                className="input"
                                placeholder="Enter Device Description"
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>Location</label>
                            <Input
                                value={createPLCDevice.location}
                                onChange={(e) => {
                                    setPLCDevice({
                                        ...createPLCDevice,
                                        location: e.target.value,
                                    });
                                }}
                                onKeyPress={() => {
                                    if (
                                        createPLCDevice?.location?.length ===
                                        maxInputLength
                                    ) {
                                        showInputError(maxInputLength);
                                    }
                                }}
                                className="input"
                                placeholder="Enter Device Location"
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="last-row">
                            <div>Read Only</div>
                            <Tooltip
                                className="tooltip"
                                title="The functionality is capable of being viewed but not changing or deleting"
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                            <Switch
                                checked={createPLCDevice.readOnly}
                                onChange={(e) => {
                                    setPLCDevice({
                                        ...createPLCDevice,
                                        readOnly: e,
                                    });
                                }}
                                className="opc-form-switch"
                            />
                        </div>
                    </Panel>
                    <Panel header={'Connection Settings'} key="2">
                        <div className="form-row">
                            <label>
                                Host Name or IP Address
                                <Tooltip
                                    className="tooltip"
                                    title="Enter Hostname or IP Address of the device e.g Localhost / 192.168.1.2"
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createPLCDevice.hostName}
                                onChange={(e) => {
                                    setPLCDevice({
                                        ...createPLCDevice,
                                        hostName: e.target.value,
                                    });
                                }}
                                className="input"
                                placeholder="Enter hostname or IP Address of Controller​"
                            />
                        </div>
                        <div className="form-row">
                            <label>
                                Port No.
                                <Tooltip
                                    className="tooltip"
                                    title="Specify the TCP/IP Port no. used for the device."
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createPLCDevice.port}
                                onChange={(e) => {
                                    setPLCNumberProperty(
                                        e.target.value,
                                        'port'
                                    );
                                }}
                                className="input"
                                placeholder="Enter Port ID"
                                maxLength={maxInputLength}
                            />
                        </div>

                        <div className="form-row">
                            <label>
                                CPU Slot No.
                                <Tooltip
                                    className="tooltip"
                                    title="Enter the slot no. of the chassis on which the CPU is installed.If the CPU is installed on slot 2 of the chassis , please enter 2"
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createPLCDevice.cpuSlotNumber}
                                onChange={(e) => {
                                    setPLCNumberProperty(
                                        e.target.value,
                                        'cpuSlotNumber'
                                    );
                                }}
                                className="input"
                                placeholder="Enter CPU Slot No.​"
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>
                                Scan Rate
                                <Tooltip
                                    className="tooltip"
                                    title=" The scan rate is the time it takes a scanner or laser to measure a mass number decade."
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createPLCDevice.scanRate}
                                onChange={(e) => {
                                    setPLCNumberProperty(
                                        e.target.value,
                                        'scanRate'
                                    );
                                }}
                                className="input"
                                placeholder="Enter Scan Rate in milli seconds e.g. 3000ms​"
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>
                                Connection timeout (seconds)
                                <Tooltip
                                    className="tooltip"
                                    title="The time limit for establishing a connection between a client and a server."
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createPLCDevice.connectionTimeout}
                                onChange={(e) => {
                                    setPLCNumberProperty(
                                        e.target.value,
                                        'connectionTimeout'
                                    );
                                }}
                                className="input"
                                placeholder="Enter time in milli seconds e.g. 3000ms​"
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>
                                Request timeout (seconds)
                                <Tooltip
                                    className="tooltip"
                                    title="The Request Timeout response status indicates that the server wishes to terminate this unused connection."
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createPLCDevice.requestTimeout}
                                onChange={(e) => {
                                    setPLCNumberProperty(
                                        e.target.value,
                                        'requestTimeout'
                                    );
                                }}
                                className="input"
                                placeholder="Enter time in milli seconds e.g. 3000ms​"
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>
                                Attempts before timeout
                                <Tooltip
                                    className="tooltip"
                                    title="Number of Attemps before timeout"
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createPLCDevice.attemptsBeforeTimeout}
                                onChange={(e) => {
                                    setPLCNumberProperty(
                                        e.target.value,
                                        'attemptsBeforeTimeout'
                                    );
                                }}
                                className="number"
                                placeholder="Enter no. of attempts (max value 10)"
                                maxLength={maxInputLength}
                            />
                        </div>
                    </Panel>
                    <Panel header={'Device Authentication'} key="3">
                        <div className="form-row">
                            <label>User Name</label>
                            <Input
                                value={createPLCDevice.userName}
                                onChange={(e) => {
                                    setPLCDevice({
                                        ...createPLCDevice,
                                        userName: e.target.value,
                                    });
                                }}
                                onKeyPress={() => {
                                    if (
                                        createPLCDevice?.userName?.length ===
                                        maxInputLength
                                    ) {
                                        showInputError(maxInputLength);
                                    }
                                }}
                                className="input"
                                placeholder="Enter username if configured in device​"
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>Password</label>
                            <Input.Password
                                value={createPLCDevice.password}
                                onChange={(e) => {
                                    setPLCDevice({
                                        ...createPLCDevice,
                                        password: e.target.value,
                                    });
                                }}
                                onKeyPress={() => {
                                    if (
                                        createPLCDevice?.password?.length ===
                                        maxInputLength
                                    ) {
                                        showInputError(maxInputLength);
                                    }
                                }}
                                className="input"
                                placeholder="Password"
                                maxLength={maxInputLength}
                            />
                        </div>
                    </Panel>
                </Collapse>
                <div className="form-action-btns">
                    <Button
                        className="back-btn"
                        onClick={removeForm}
                        size="middle"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={createNewPLCDevice}
                        className="next-btn"
                        type="default"
                        size="middle"
                    >
                        Create Device
                    </Button>
                </div>
            </div>
        </>
    );
};
