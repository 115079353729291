// Attribute

export const SHOW_LOADER_SUCCESS = 'SHOW_LOADER_SUCCESS';
export const SET_ATTRIBUTE_STATE = 'SET_ATTRIBUTE_STATE';
export const GET_ATTRIBUTE_LIST = 'GET_ATTRIBUTE_LIST';
export const GET_ATTRIBUTE_LIST_SUCCESS = 'GET_ATTRIBUTE_LIST_SUCCESS';
export const GET_ATTRIBUTE_LIST_FAILURE = 'GET_ATTRIBUTE_LIST_FAILURE';
export const GET_ATTRIBUTE_DETAILS = 'GET_ATTRIBUTE_DETAILS';
export const GET_ATTRIBUTE_DETAIL_SUCCESS = 'GET_ATTRIBUTE_DETAIL_SUCCESS';
export const GET_ATTRIBUTE_DETAIL_FAILURE = 'GET_ATTRIBUTE_DETAIL_FAILURE';
export const GET_INDUSTRY_LIST = 'GET_INDUSTRY_LIST';
export const GET_INDUSTRY_LIST_SUCCESS = 'GET_INDUSTRY_LIST_SUCCESS';
export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS';
export const GET_VALUE_TYPE_LIST = 'GET_VALUE_TYPE_LIST';
export const GET_VALUE_TYPE_LIST_SUCCESS = 'GET_VALUE_TYPE_LIST_SUCCESS';
export const GET_PROPERTY_LIST = 'GET_PROPERTY_LIST';
export const GET_PROPERTY_LIST_SUCCESS = 'GET_PROPERTY_LIST_SUCCESS';
export const GET_DATA_REFERENCE_LIST = 'GET_DATA_REFERENCE_LIST';
export const GET_DATA_REFERENCE_LIST_SUCCESS =
    'GET_DATA_REFERENCE_LIST_SUCCESS';
export const GET_UOM_LIST = 'GET_UOM_LIST';
export const GET_UOM_LIST_SUCCESS = 'GET_UOM_LIST_SUCCESS';
export const GET_UOM_CLASS_LIST = 'GET_UOM_CLASS_LIST';
export const GET_UOM_CLASS_LIST_SUCCESS = 'GET_UOM_CLASS_LIST_SUCCESS';
export const GET_UOM_METRIC_LIST = 'GET_UOM_METRIC_LIST';
export const GET_UOM_METRIC_LIST_SUCCESS = 'GET_UOM_METRIC_LIST_SUCCESS';
export const CREATE_UOM_CLASS = 'CREATE_UOM_CLASS';
export const CREATE_UOM_CLASS_SUCCESS = 'CREATE_UOM_CLASS_SUCCESS';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_INDUSTRY = 'CREATE_INDUSTRY';
export const CREATE_INDUSTRY_SUCCESS = 'CREATE_INDUSTRY_SUCCESS';
export const CREATE_UOM = 'CREATE_UOM';
export const CREATE_UOM_SUCCESS = 'CREATE_UOM_SUCCESS';
export const CREATE_UOM_FAILURE = 'CREATE_UOM_FAILURE';
export const CREATE_ATTRIBUTE = 'CREATE_ATTRIBUTE';
export const CREATE_ATTRIBUTE_SUCCESS = 'CREATE_ATTRIBUTE_SUCCESS';
export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE';
export const UPDATE_ATTRIBUTE_SUCCESS = 'UPDATE_ATTRIBUTE_SUCCESS';
export const STATUS_UPDATE_ATTRIBUTE = 'STATUS_UPDATE_ATTRIBUTE';
export const STATUS_UPDATE_ATTRIBUTE_SUCCESS =
    'STATUS_UPDATE_ATTRIBUTE_SUCCESS';
export const ATTRUBUTE_FIELDS_VALUES = 'ATTRUBUTE_FIELDS_VALUES'
