export const UPDATE_DATE_FILTER = 'UPDATE_DATE_FILTER';

export const GET_UTILIZATION = 'GET_UTILIZATION';
export const GET_UTILIZATION_SUCCESS = 'GET_UTILIZATION_SUCCESS';
export const GET_UTILIZATION_FAIL = 'GET_UTILIZATION_FAIL';

export const GET_CURRENTVALUES = 'GET_CURRENTVALUES';
export const GET_CURRENTVALUES_SUCCESS = 'GET_CURRENTVALUES_SUCCESS';
export const GET_CURRENTVALUES_FAIL = 'GET_CURRENTVALUES_FAIL';

export const KPI_METADATA = 'KPI_METADATA';
export const KPI_METADATA_SUCCESS = 'KPI_METADATA_SUCCESS';
export const KPI_METADATA_FAIL = 'KPI_METADATA_FAIL';

export const GET_AGGREGATE_VALUES = 'GET_AGGREGATE_VALUES';
export const GET_AGGREGATE_VALUES_SUCCESS = 'GET_AGGREGATE_VALUES_SUCCESS';
export const GET_AGGREGATE_VALUES_FAIL = 'GET_AGGREGATE_VALUES_FAIL';

export const GET_POWER_CONSUMPTION_VALUES = 'GET_POWER_CONSUMPTION_VALUES';
export const GET_POWER_CONSUMPTION_VALUES_SUCCESS =
    'GET_POWER_CONSUMPTION_VALUES_SUCCESS';
export const GET_POWER_CONSUMPTION_VALUES_FAIL =
    'GET_POWER_CONSUMPTION_VALUES_FAIL';

export const GET_ECONOMISER_AND_APH_VALUES = 'GET_ECONOMISER_AND_APH_VALUES';
export const GET_ECONOMISER_AND_APH_VALUES_SUCCESS =
    'GET_ECONOMISER_AND_APH_VALUES_SUCCESS';
export const GET_ECONOMISER_AND_APH_VALUES_FAIL =
    'GET_ECONOMISER_AND_APH_VALUES_FAIL';

export const GET_AVG_OXYGEN_VALUES = 'GET_AVG_OXYGEN_VALUES';
export const GET_AVG_OXYGEN_VALUES_SUCCESS = 'GET_AVG_OXYGEN_VALUES_SUCCESS';
export const GET_AVG_OXYGEN_VALUES_FAIL = 'GET_AVG_OXYGEN_VALUES_FAIL';

export const GET_FUEL_RATIO_VALUES = 'GET_FUEL_RATIO_VALUES';
export const GET_FUEL_RATIO_VALUES_SUCCESS = 'GET_FUEL_RATIO_VALUES_SUCCESS';
export const GET_FUEL_RATIO_VALUES_FAIL = 'GET_FUEL_RATIO_VALUES_FAIL';

export const GET_CHOOSE_PARAM_VALUES = 'GET_CHOOSE_PARAM_VALUES';
export const GET_CHOOSE_PARAM_VALUES_SUCCESS =
    'GET_CHOOSE_PARAM_VALUES_SUCCESS';
export const GET_CHOOSE_PARAM_VALUES_FAIL = 'GET_CHOOSE_PARAM_VALUES_FAIL';

export const GET_STEAM_RATE = 'GET_STEAM_RATE';
export const GET_STEAM_RATE_SUCCESS = 'GET_STEAM_RATE_SUCCESS';
export const GET_STEAM_RATE_FAIL = 'GET_STEAM_RATE_FAIL';

export const GET_FEED_WATER_TREND = 'GET_FEED_WATER_TREND';
export const GET_FEED_WATER_TREND_SUCCESS = 'GET_FEED_WATER_TREND_SUCCESS';
export const GET_FEED_WATER_TREND_FAIL = 'GET_FEED_WATER_TREND_FAIL';

export const GET_STEAM_TEMP_TREND = 'GET_STEAM_TEMP_TREND';
export const GET_STEAM_TEMP_TREND_SUCCESS = 'GET_STEAM_TEMP_TREND_SUCCESS';
export const GET_STEAM_TEMP_TREND_FAIL = 'GET_STEAM_TEMP_TREND_FAIL';

export const GET_STEAM_PRESSURE_TREND = 'GET_STEAM_PRESSURE_TREND';
export const GET_STEAM_PRESSURE_TREND_SUCCESS =
    'GET_STEAM_PRESSURE_TREND_SUCCESS';
export const GET_STEAM_PRESSURE_TREND_FAIL = 'GET_STEAM_PRESSURE_TREND_FAIL';

export const UPDATE_PARAMETERS = 'UPDATE_PARAMETERS';

export const GET_PARAMETER_TREND_DATA = 'GET_PARAMETER_TREND_DATA';
export const GET_PARAMETER_TREND_DATA_SUCCESS =
    'GET_PARAMETER_TREND_DATA_SUCCESS';
export const GET_PARAMETER_TREND_DATA_FAIL = 'GET_PARAMETER_TREND_DATA_FAIL';
export const GET_COAL_INTAKE_PROD = 'GET_COAL_INTAKE_PROD';
export const GET_COAL_INTAKE_PROD_SUCCESS = 'GET_COAL_INTAKE_PROD_SUCCESS';
export const GET_COAL_INTAKE_PROD_FAIL = 'GET_COAL_INTAKE_PROD_FAIL';

export const GET_REDUCTION_VALUES = 'GET_REDUCTION_VALUES';
export const GET_REDUCTION_VALUES_SUCCESS = 'GET_REDUCTION_VALUES_SUCCESS';
export const GET_REDUCTION_VALUES_FAIL = 'GET_REDUCTION_VALUES_FAIL';
export const GET_KPI_TREND = 'GET_KPI_TREND';
export const GET_KPI_TREND_SUCCESS = 'GET_KPI_TREND_SUCCESS';
export const GET_KPI_TREND_FAIL = 'GET_KPI_TREND_FAIL';

export const SET_KPI_TREND = 'SET_KPI_TREND';
export const GET_STEAM_FLOW_RATE = 'GET_STEAM_FLOW_RATE';
export const GET_STEAM_FLOW_RATE_SUCCESS = 'GET_STEAM_FLOW_RATE_SUCCESS';
export const GET_STEAM_FLOW_RATE_FAIL = 'GET_STEAM_FLOW_RATE_FAIL';

export const GET_COAL_CONSUMPTION = 'GET_COAL_CONSUMPTION';
export const GET_COAL_CONSUMPTION_SUCCESS = 'GET_COAL_CONSUMPTION_SUCCESS';
export const GET_COAL_CONSUMPTION_FAIL = 'GET_COAL_CONSUMPTION_FAIL';

// Manual Entry Types
export const GET_ALL_MANUAL_ENTRY_PARAMS = 'GET_ALL_MANUAL_ENTRY_PARAMS';
export const GET_ALL_MANUAL_ENTRY_PARAMS_SUCCESS =
    'GET_ALL_MANUAL_ENTRY_PARAMS_SUCCESS';
export const GET_ALL_MANUAL_ENTRY_PARAMS_FAIL =
    'GET_ALL_MANUAL_ENTRY_PARAMS_FAIL';

export const GET_MANUAL_ENTRY_VALUE_PARAMS = 'GET_MANUAL_ENTRY_VALUE_PARAMS';
export const GET_MANUAL_ENTRY_VALUE_PARAMS_SUCCESS =
    'GET_MANUAL_ENTRY_VALUE_PARAMS_SUCCESS';
export const GET_MANUAL_ENTRY_VALUE_PARAMS_FAIL =
    'GET_MANUAL_ENTRY_VALUE_PARAMS_FAIL';

export const SET_MANUAL_ENTRY_VALUE_PARAMS = 'SET_MANUAL_ENTRY_VALUE_PARAMS';
export const SET_MANUAL_ENTRY_VALUE_PARAMS_SUCCESS =
    'SET_MANUAL_ENTRY_VALUE_PARAMS_SUCCESS';
export const SET_MANUAL_ENTRY_VALUE_PARAMS_FAIL =
    'SET_MANUAL_ENTRY_VALUE_PARAMS_FAIL';

// Download KPI Data

export const GET_KPI_CSV = 'GET_KPI_CSV';
export const GET_KPI_CSV_SUCCESS = 'GET_KPI_CSV_SUCCESS';
export const GET_KPI_CSV_FAIL = 'GET_KPI_CSV_FAIL';
export const OPEN_ASSUMPTION_AND_FORMULA = 'OPEN_ASSUMPTION_AND_FORMULA';
export const CLOSE_ASSUMPTION_AND_FORMULA = 'CLOSE_ASSUMPTION_AND_FORMULA';

// Operator Entry Types

export const GET_ALL_OPERATOR_ENTRY_PARAMS = 'GET_ALL_OPERATOR_ENTRY_PARAMS';
export const GET_ALL_OPERATOR_ENTRY_PARAMS_SUCCESS =
    'GET_ALL_OPERATOR_ENTRY_PARAMS_SUCCESS';
export const GET_ALL_OPERATOR_ENTRY_PARAMS_FAIL =
    'GET_ALL_OPERATOR_ENTRY_PARAMS_FAIL';

export const GET_ALL_SUPERVISOR_PARAMS = 'GET_ALL_SUPERVISOR_PARAMS';
export const GET_ALL_SUPERVISOR_PARAMS_SUCCESS =
    'GET_ALL_SUPERVISOR_PARAMS_SUCCESS';
export const GET_ALL_SUPERVISOR_PARAMS_FAIL = 'GET_ALL_SUPERVISOR_PARAMS_FAIL';

export const SET_OPERATOR_ENTRY_VALUE_PARAMS =
    'SET_OPERATOR_ENTRY_VALUE_PARAMS';
export const SET_OPERATOR_ENTRY_VALUE_PARAMS_SUCCESS =
    'SET_OPERATOR_ENTRY_VALUE_PARAMS_SUCCESS';
export const SET_OPERATOR_ENTRY_VALUE_PARAMS_FAIL =
    'SET_OPERATOR_ENTRY_VALUE_PARAMS_FAIL';

export const GET_OPERATOR_LAST_SHIFT_DATA = 'GET_OPERATOR_LAST_SHIFT_DATA';
export const GET_OPERATOR_LAST_SHIFT_DATA_SUCCESS =
    'GET_OPERATOR_LAST_SHIFT_DATA_SUCCESS';
export const GET_OPERATOR_LAST_SHIFT_DATA_FAIL =
    'GET_OPERATOR_LAST_SHIFT_DATA_FAIL';
