import { Button, Collapse, Input, Switch, Tooltip, message } from 'antd';
import { useCallback, useState } from 'react';
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    InfoCircleOutlined,
} from '@ant-design/icons';
import './createopcform.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addDevice } from 'redux/actions/DeviceManagementActions/deviceAction';
import { type CreateOPCDevice } from 'types/interfaces/PropsInterfaces/DeviceManagement/devices.interface';
import { deviceType } from 'types/enums';
import { parseJwt } from 'utils/jwtTokenFunction';
import {
    debounceError,
    handleKeyInput,
    useCharacterLimit,
} from 'utils/commonFunction';
import { maxDeviceNameLength, maxInputLength } from 'utils/constants';

export const genDeviceInformation = (): any => (
    <>
        <div className="header">
            <div className="heading">Device Information</div>
            <Tooltip title="Enter you device details in below such as Device Name , Description & Locations. Device name is unique, Once created it cannot be changed">
                <InfoCircleOutlined />
            </Tooltip>
        </div>
    </>
);

export const genConnectionInformation = (): any => (
    <>
        <div className="header">
            <div className="heading">Connection Settings</div>
            <Tooltip title="Connection settings configured in the device">
                <InfoCircleOutlined />
            </Tooltip>
        </div>
    </>
);

export const genDeviceAuthentication = (): any => (
    <>
        <div className="header">
            <div className="heading">Device Authentication</div>
            <Tooltip title=" Device authentication is a security mechanism that ensures only authorised devices can connect to a specific network, site, or service.">
                <InfoCircleOutlined />
            </Tooltip>
        </div>
    </>
);

export const CreateOPCForm: any = (props: any) => {
    const emptyFormObject: CreateOPCDevice = {
        deviceName: '',
        description: '',
        location: '',
        readOnly: true,
        deviceType: 'OPC',
        endPointURL: '',
        securityMode: '',
        securityPolicy: '',
        connectionTimeout: '',
        opcUserName: '',
        opcPassword: '',
        deviceId: '',
        requestTimeout: '',
        attemptsBeforeTimeout: '',
        scanRate: '',
        portNo: '',
    };

    const userDetails = parseJwt();
    const [createOPCDevice, setOPCDevice] =
        useState<CreateOPCDevice>(emptyFormObject);
    const { Panel } = Collapse;
    const dispatch = useDispatch();
    const blaIdDevices = useSelector(
        (state: any) => state.deviceManagement.devices?.selectedBla[0]?.blaId
    );
    const blaId = useSelector(
        (state: any) => state.deviceManagement.blas.blaId
    );

    const selectedBlaBlasTab = useSelector(
        (state: any) =>
            state.deviceManagement.blas.lastCreatedBla?.blaDetails?.data.blaId
    );

    const showMessageError = useCallback(
        debounceError((errorMsg: string) => {
            message.error(errorMsg);
        }, 100),
        []
    );

    const setOPCNumberProperty = (val: any, property: any): void => {
        const pattern = /^[0-9]*$/;
        if (!pattern.test(val)) {
            showMessageError('Please enter a valid number');
        } else if (val.length > 8) {
            return undefined;
        } else {
            setOPCDevice({ ...createOPCDevice, [property]: val });
        }
    };
    const createNewOpcDevice = (): any => {
        const formObject: CreateOPCDevice = createOPCDevice;
        const payload: any = {};
        payload.name = formObject.deviceName;
        payload.description = formObject.description;
        payload.deviceTypeId = deviceType.opcUa;
        payload.blaId =
            selectedBlaBlasTab ||
            blaIdDevices ||
            props?.blaDetails?.blaId ||
            blaId;
        payload.location = formObject.location;
        payload.readOnly = formObject.readOnly;
        payload.deviceAuthentication = {
            username: formObject.opcUserName,
            password: formObject.opcPassword,
        };
        payload.opcUaConnectionDetails = {
            endpointUrl: formObject.endPointURL,
            secMode: formObject.securityMode,
            secPolicy: formObject.securityPolicy,
        };
        payload.scanRate = formObject.scanRate;
        payload.requestTimeout = formObject.requestTimeout;
        payload.attemptsBeforeTimeout = formObject.attemptsBeforeTimeout;
        payload.requestedBy = userDetails.username;
        dispatch(addDevice(payload));
    };

    const removeForm = (): any => {
        setOPCDevice(emptyFormObject);
        props.setShowDrawer(false);
    };
    const { showInputError } = useCharacterLimit();

    return (
        <>
            <div className="opcForm form-container">
                <p className="form-heading fs-14 fw-400">
                    New Device – Connection to OPC Server
                </p>

                <Collapse defaultActiveKey={['1', '2', '3']}>
                    <Panel
                        className="opcForm__collapse devices-panel"
                        header={genDeviceInformation()}
                        key="1"
                    >
                        <div className="form-row">
                            <label>Device Name</label>
                            <Input
                                value={createOPCDevice.deviceName}
                                className="input"
                                placeholder="Enter Device Name"
                                required
                                onChange={(e) => {
                                    setOPCDevice({
                                        ...createOPCDevice,
                                        deviceName: e.target.value,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (
                                        createOPCDevice?.deviceName?.length ===
                                        maxDeviceNameLength
                                    ) {
                                        showInputError(maxDeviceNameLength);
                                    }
                                    handleKeyInput(
                                        e,
                                        createOPCDevice?.deviceName
                                    );
                                }}
                                maxLength={maxDeviceNameLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>Description</label>
                            <Input
                                value={createOPCDevice.description}
                                className="input"
                                placeholder="Enter Device Description"
                                onChange={(e) => {
                                    setOPCDevice({
                                        ...createOPCDevice,
                                        description: e.target.value,
                                    });
                                }}
                                onKeyPress={() => {
                                    if (
                                        createOPCDevice?.description?.length ===
                                        maxInputLength
                                    ) {
                                        showInputError(maxInputLength);
                                    }
                                }}
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>Location</label>
                            <Input
                                value={createOPCDevice.location}
                                className="input"
                                placeholder="Enter Device Location"
                                onChange={(e) => {
                                    setOPCDevice({
                                        ...createOPCDevice,
                                        location: e.target.value,
                                    });
                                }}
                                onKeyPress={() => {
                                    if (
                                        createOPCDevice?.location?.length ===
                                        maxInputLength
                                    ) {
                                        showInputError(maxInputLength);
                                    }
                                }}
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="last-row">
                            <div>Read Only</div>
                            <Tooltip
                                className="tooltip"
                                title="The functionality is capable of being viewed but not changing or deleting"
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                            <Switch
                                checked={createOPCDevice.readOnly}
                                className="opc-form-switch"
                                onChange={(e) => {
                                    setOPCDevice({
                                        ...createOPCDevice,
                                        readOnly: e,
                                    });
                                }}
                            />
                        </div>
                    </Panel>
                    <Panel
                        className="devices-panel"
                        header={genConnectionInformation()}
                        key="2"
                    >
                        <div className="form-row">
                            <label>
                                Host name or IP Address
                                <Tooltip
                                    className="tooltip"
                                    title="It is a web address (URL) through which clients of a specific service can access it."
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createOPCDevice.endPointURL}
                                className="input"
                                placeholder="Enter OPC End point URL"
                                onChange={(e) => {
                                    setOPCDevice({
                                        ...createOPCDevice,
                                        endPointURL: e.target.value,
                                    });
                                }}
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>
                                Port No
                                <Tooltip
                                    className="tooltip"
                                    title="Port on which device runs"
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createOPCDevice.portNo}
                                className="input"
                                placeholder="Enter Port number"
                                onChange={(e) => {
                                    setOPCNumberProperty(
                                        e.target.value,
                                        'portNo'
                                    );
                                }}
                                maxLength={maxInputLength}
                            />
                        </div>

                        <div className="form-row">
                            <label>
                                Scan Rate
                                <Tooltip
                                    className="tooltip"
                                    title=" The scan rate is the time it takes a scanner or laser to measure a mass number decade."
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createOPCDevice.scanRate}
                                onChange={(e) => {
                                    setOPCNumberProperty(
                                        e.target.value,
                                        'scanRate'
                                    );
                                }}
                                className="input"
                                placeholder="Enter Scan Rate in milli seconds e.g. 3000ms​"
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>
                                Connection timeout (seconds)
                                <Tooltip
                                    className="tooltip"
                                    title="The time limit for establishing a connection between a client and a server."
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createOPCDevice.connectionTimeout}
                                onChange={(e) => {
                                    setOPCNumberProperty(
                                        e.target.value,
                                        'connectionTimeout'
                                    );
                                }}
                                className="input"
                                placeholder="Enter time in milli seconds e.g. 3000ms​"
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>
                                Request timeout (seconds)
                                <Tooltip
                                    className="tooltip"
                                    title="The Request Timeout response status indicates that the server wishes to terminate this unused connection."
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createOPCDevice.requestTimeout}
                                onChange={(e) => {
                                    setOPCNumberProperty(
                                        e.target.value,
                                        'requestTimeout'
                                    );
                                }}
                                className="input"
                                placeholder="Enter time in milli seconds e.g. 3000ms​"
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>
                                Attempts before timeout
                                <Tooltip
                                    className="tooltip"
                                    title="Number of Attemps before timeout"
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </label>
                            <Input
                                value={createOPCDevice.attemptsBeforeTimeout}
                                onChange={(e) => {
                                    setOPCNumberProperty(
                                        e.target.value,
                                        'attemptsBeforeTimeout'
                                    );
                                }}
                                className="number"
                                placeholder="Enter no. of attempts (max value 10)​"
                                maxLength={maxInputLength}
                            />
                        </div>
                    </Panel>
                    <Panel
                        className="devices-panel"
                        header={genDeviceAuthentication()}
                        key="3"
                    >
                        <div className="form-row">
                            <label>Username</label>
                            <Input
                                value={createOPCDevice.opcUserName}
                                className="input"
                                placeholder="Enter username if configured in device​"
                                onChange={(e) => {
                                    setOPCDevice({
                                        ...createOPCDevice,
                                        opcUserName: e.target.value,
                                    });
                                }}
                                onKeyPress={() => {
                                    if (
                                        createOPCDevice?.opcUserName?.length ===
                                        maxInputLength
                                    ) {
                                        showInputError(maxInputLength);
                                    }
                                }}
                                maxLength={maxInputLength}
                            />
                        </div>
                        <div className="form-row">
                            <label>Password</label>
                            <Input.Password
                                className="inputPassword"
                                placeholder="Password"
                                iconRender={(visible) =>
                                    visible ? (
                                        <EyeTwoTone />
                                    ) : (
                                        <EyeInvisibleOutlined />
                                    )
                                }
                                value={createOPCDevice.opcPassword}
                                onChange={(e) => {
                                    setOPCDevice({
                                        ...createOPCDevice,
                                        opcPassword: e.target.value,
                                    });
                                }}
                                onKeyPress={() => {
                                    if (
                                        createOPCDevice?.opcPassword?.length ===
                                        maxInputLength
                                    ) {
                                        showInputError(maxInputLength);
                                    }
                                }}
                                maxLength={maxInputLength}
                            />
                        </div>
                    </Panel>
                </Collapse>
                <div className="form-action-btns">
                    <Button
                        className="back-btn"
                        onClick={removeForm}
                        size="middle"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={createNewOpcDevice}
                        className="next-btn"
                        type="default"
                        size="middle"
                    >
                        Create Device
                    </Button>
                </div>
            </div>
        </>
    );
};
