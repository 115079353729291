import { all, fork } from 'redux-saga/effects';
import { sideNavSaga } from './NavSagas/navSaga';
import { usersSaga } from './UserManagementSagas/userSaga';
import { groupsSaga } from './UserManagementSagas/groupsSaga';
import { loginSaga } from './AuthSaga/authSaga';
import { roleSaga } from './UserManagementSagas/rolesSaga';
import { bulkUploadSaga } from './BulkUploadSaga/bulkUploadSaga';
import { blaSaga } from './DeviceManagementSaga/blaSaga';
import { tagSaga } from './DeviceManagementSaga/tagSaga';
import { deviceSaga } from './DeviceManagementSaga/deviceSaga';
import { nocilSaga } from './NocilDashboard/nocilSaga';
import { manualSaga } from './ManualEntrySaga/manualEntrySaga';
import { calendarConfiguratorSaga } from './CalendarConfiguratorSaga';
import { operatorSaga } from './OperatorEntrySaga/operatorEntrySaga';
import { dataVisualizationSaga } from './DataExplorerSaga/dataVisualizationSaga';
import { dashboardReportingSaga } from './DataExplorerSaga/dashboardReportingSaga';
import { attributeSaga } from './ConfigureSaga/attributeSaga';

export const rootSaga = function* root(): any {
    yield all([fork(sideNavSaga)]);
    yield all([fork(usersSaga)]);
    yield all([fork(loginSaga)]);
    yield all([fork(groupsSaga)]);
    yield all([fork(roleSaga)]);
    yield all([fork(blaSaga)]);
    yield all([fork(tagSaga)]);
    yield all([fork(bulkUploadSaga)]);
    yield all([fork(deviceSaga)]);
    yield all([fork(dataVisualizationSaga)]);
    yield all([fork(nocilSaga)]);
    yield all([fork(manualSaga)]);
    yield all([fork(calendarConfiguratorSaga)]);
    yield all([fork(operatorSaga)]);
    yield all([fork(attributeSaga)]);
    yield all([fork(dashboardReportingSaga)]);
};
