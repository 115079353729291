import { Button, Col, Divider, Drawer, Form, Input, Row, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import CustomButton from 'components/common/CustomButton';
import './index.scss';
import { useEffect, useState } from 'react';
import { parseJwt } from 'utils/jwtTokenFunction';
import { useDispatch } from 'react-redux';
import { editDevice } from 'redux/actions/DeviceManagementActions/deviceAction';
import { mapDeviceDetailsToRequestBody } from '../../editDeviceCommonCode';
import { maxInputLength } from 'utils/constants';

const DevicesSettings: React.FC<any> = ({
    open,
    setBlaState,
    deviceDescription,
    deviceDetails,
    setOpenEditDevice,
    onClose,
    showSettings,
    openEditDevicesDrawer,
}) => {
    const details = parseJwt();
    const dispatch = useDispatch();

    const [payload, setPayload] = useState({
        scanRate: '',
        requestTimeOut: '',
    });
    const { scanRate, requestTimeOut } = payload;
    const [deviceRequestBody, setDeviceRequestBody] = useState<any>({});
    useEffect(() => {
        setDeviceRequestBody(deviceDetails);
    }, [deviceDetails]);
    const detailsHandler = (
        property: string,
        value: string | boolean
    ): void => {
        setPayload({ ...payload, [property]: value });
    };
    const submitDetails = (): void => {
        const requestBody: any =
            mapDeviceDetailsToRequestBody(deviceRequestBody);
        requestBody.deviceId = deviceDetails?.deviceId;
        requestBody.description = deviceDescription;
        requestBody.requestedBy = details?.username;
        requestBody.scanRate = scanRate;
        requestBody.requestTimeOut = requestTimeOut;
        dispatch(editDevice(requestBody));
        setOpenEditDevice(false);
        openEditDevicesDrawer();
        showSettings(false);
        onClose();
    };
    const initialValues = {
        scanRate: deviceDetails?.scanRate || '',
        requestTimeOut: deviceDetails?.requestTimeOut || '',
        hostName:
            deviceDetails?.opcUaConnectionDetails?.endpointUrl ||
            deviceDetails?.plcAbConnectionDetails?.ipAddress ||
            '',
        port: deviceDetails?.plcAbConnectionDetails?.port || '',
        cpuSlotNumber: deviceDetails?.plcAbConnectionDetails?.cpuSlot || '',
    };
    return (
        <>
            <Drawer
                key={open}
                className="editBlaDrawer"
                placement="right"
                getContainer={false}
                size="large"
                closable={false}
                open={open}
            >
                <>
                    <div>
                        <Row>
                            <Col span={2}>
                                <Button
                                    type="text"
                                    onClick={() => showSettings(false)}
                                    icon={<BackIcon />}
                                ></Button>
                            </Col>
                            <Col span={18}>
                                <span className="drawer-title">Settings</span>
                            </Col>
                            <Col span={4}>
                                <Button
                                    type="text"
                                    onClick={() => setBlaState(false)}
                                    icon={<CloseOutlined />}
                                ></Button>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col span={8}>Device Name</Col>
                            <Col span={8}>Device Type</Col>
                            <Col span={8}>PLC Model</Col>
                        </Row>
                        <Row>
                            <Col span={8} className="device__details">
                                {deviceDetails?.deviceName}
                            </Col>
                            <Col span={8} className="device__details">
                                {deviceDetails?.communicationInterface}
                            </Col>
                            <Col span={8} className="device__details">
                                {deviceDetails?.plcModel}
                            </Col>
                        </Row>
                        <Divider />
                        <Form
                            layout="vertical"
                            className={'editDeviceWrapper__deviceEditList'}
                            onFinish={submitDetails}
                            initialValues={initialValues}
                        >
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Parent Label"
                                        name="parentLabel"
                                    >
                                        <Select placeholder="Select" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Filter Tags"
                                        name="filterTags"
                                    >
                                        <Select placeholder="Select" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Host Name or IP Address"
                                        name="hostName"
                                    >
                                        <Input
                                            className="input-field"
                                            maxLength={maxInputLength}
                                            onChange={(e) => {
                                                if (
                                                    deviceRequestBody?.opcUaConnectionDetails
                                                ) {
                                                    setDeviceRequestBody({
                                                        ...deviceRequestBody,
                                                        opcUaConnectionDetails:
                                                            {
                                                                ...deviceRequestBody?.opcUaConnectionDetails,
                                                                endpointUrl:
                                                                    e.target
                                                                        .value,
                                                            },
                                                    });
                                                }
                                                if (
                                                    deviceRequestBody?.plcAbConnectionDetails
                                                ) {
                                                    setDeviceRequestBody({
                                                        ...deviceRequestBody,
                                                        plcAbConnectionDetails:
                                                            {
                                                                ...deviceRequestBody?.plcAbConnectionDetails,
                                                                ipAddress:
                                                                    e.target
                                                                        .value,
                                                            },
                                                    });
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Port" name="port">
                                        <Input
                                            maxLength={maxInputLength}
                                            className="input-field"
                                            onChange={(e) => {
                                                setDeviceRequestBody({
                                                    ...deviceRequestBody,
                                                    plcAbConnectionDetails: {
                                                        ...deviceRequestBody?.plcAbConnectionDetails,
                                                        port: e.target.value,
                                                    },
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item
                                        label="CPU Slot Number"
                                        name="cpuSlotNumber"
                                    >
                                        <Input
                                            maxLength={maxInputLength}
                                            className="input-field"
                                            onChange={(e) => {
                                                setDeviceRequestBody({
                                                    ...deviceRequestBody,
                                                    plcAbConnectionDetails: {
                                                        ...deviceRequestBody?.plcAbConnectionDetails,
                                                        cpuSlot: e.target.value,
                                                    },
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Scan Rate"
                                        name="scanRate"
                                    >
                                        <Input
                                            className="input-field"
                                            value={scanRate}
                                            onChange={(e) => {
                                                detailsHandler(
                                                    'scanRate',
                                                    e.target.value
                                                );
                                            }}
                                            maxLength={maxInputLength}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Connection Timeout"
                                        name="connectionTimeout"
                                    >
                                        <Input
                                            className="input-field"
                                            maxLength={maxInputLength}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Request Timeout"
                                        name="requestTimeOut"
                                    >
                                        <Input
                                            className="input-field"
                                            value={requestTimeOut}
                                            onChange={(e) => {
                                                detailsHandler(
                                                    'requestTimeOut',
                                                    e.target.value
                                                );
                                            }}
                                            maxLength={maxInputLength}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="button">
                                <Divider />
                                <Row className="footerButtons">
                                    <Col
                                        span={4}
                                        className="deviceSettingDrawer__cancelButton"
                                    >
                                        <CustomButton
                                            type={'Cancel'}
                                            disabled={false}
                                            handleClick={() => {
                                                setOpenEditDevice(false);
                                                openEditDevicesDrawer();
                                                showSettings(false);
                                                onClose();
                                            }}
                                        />
                                    </Col>
                                    <Col
                                        span={4}
                                        className="deviceSettingDrawer__saveButton"
                                    >
                                        <CustomButton
                                            type={'Save'}
                                            disabled={false}
                                            typeOfButton={'submit'}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </div>
                </>
            </Drawer>
        </>
    );
};

export default DevicesSettings;
