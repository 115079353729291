import * as types from 'redux/types/configureTypes';
import initialState from '../initialStates';

export default function configureReducer(
    state = initialState.configure,
    action: any
): any {
    switch (action.type) {
        case types.SET_ATTRIBUTE_STATE:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    attributeState: action.payload,
                },
            };
            return state;
        case types.GET_ATTRIBUTE_LIST:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    attributesListLoading: true,
                },
            };
            return state;
        case types.GET_ATTRIBUTE_LIST_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    attributesList: action.response.data,
                    attributesListLoading: false,
                    createAttribute: false,
                    createUom: false,
                    updateAttribute: false,
                    statusUpdateAttribute: false,
                    attributeDetails: {},
                },
            };
            return state;
        case types.GET_ATTRIBUTE_LIST_FAILURE:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    attributesListLoading: false,
                },
            };
            return state;
        case types.GET_ATTRIBUTE_DETAILS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    attributesDetailLoading: true,
                },
            };
            return state;
        case types.GET_ATTRIBUTE_DETAIL_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    attributeDetails: action.response.data,
                    attributesDetailLoading: false,
                },
            };
            return state;
        case types.GET_ATTRIBUTE_DETAIL_FAILURE:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    attributesDetailLoading: false,
                },
            };
            return state;
        case types.GET_INDUSTRY_LIST_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    industryList: action.response.data,
                    createIndustry: false,
                },
            };
            return state;
        case types.GET_CATEGORY_LIST_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    categoryList: action.response.data,
                    createCategory: false,
                },
            };
            return state;
        case types.GET_DATA_REFERENCE_LIST_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    dataReferenceList: action.response.data,
                },
            };
            return state;
        case types.GET_VALUE_TYPE_LIST_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    valueTypeList: action.response.data,
                },
            };
            return state;
        case types.GET_PROPERTY_LIST_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    propertyList: action.response.data,
                },
            };
            return state;
        case types.GET_UOM_LIST_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    uomList: action.response.data,
                    createUom: false,
                },
            };
            return state;
        case types.GET_UOM_CLASS_LIST_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    uomClassList: action.response.data,
                    createUomClass: false,
                },
            };
            return state;
        case types.GET_UOM_METRIC_LIST_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    uomMetricList: action.response.data,
                },
            };
            return state;
        case types.CREATE_UOM_CLASS_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    createUomClass: true,
                },
            };
            return state;
        case types.CREATE_CATEGORY_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    createCategory: true,
                },
            };
            return state;
        case types.CREATE_INDUSTRY_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    createIndustry: true,
                },
            };
            return state;
        case types.CREATE_UOM_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    createUom: true,
                },
            };
            return state;
        case types.CREATE_UOM_FAILURE:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    createUom: false,
                },
            };
            return state;
        case types.CREATE_ATTRIBUTE_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    createAttribute: true,
                },
            };
            return state;
        case types.UPDATE_ATTRIBUTE_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    updateAttribute: true,
                },
            };
            return state;
        case types.STATUS_UPDATE_ATTRIBUTE_SUCCESS:
            state = {
                ...state,
                attributes: {
                    ...state.attributes,
                    statusUpdateAttribute: true,
                },
            };
            return state;
            case types.ATTRUBUTE_FIELDS_VALUES:
                state = {
                    ...state,
                    attributesFieldsValues: action.payload
                };
                return state;
        default:
            return state;
    }
}
