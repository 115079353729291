import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import { rootSaga } from "redux/saga/index";
import sideNavReducer from "./reducers/sidenavReducer";
import loginReducer from "./reducers/AuthReducers/authReducer";
import userManagementReducer from "./reducers/UserManagementReducers/userManagementReducers";
import deviceManagementReducer from "./reducers/DeviceManagementReducers/deviceManagementReducers";
import nocilDashboardReducer from "./reducers/NocilDashboardReducers/nocilDashboardReducers";
import dataVisualizationReducer from "./reducers/DataVisualizationReducers";
import manualEntryReducer from "./reducers/ManualEntryReducers/manualEntryReducer";
import calendarConfiguratorReducer from "./reducers/CalendarConfiguratorReducers";
import operatorEntryReducer from "./reducers/OperatorEntryReducers/operatorEntryReducer";
import dashboardReportingReducers from "./reducers/DataExplorerReducers/DashboardReportingReducers";
import configureReducer from './reducers/ConfigureReducers/configureReducer';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    root: sideNavReducer,
    login: loginReducer,
    userManagement: userManagementReducer,
    deviceManagement: deviceManagementReducer,
    nocilDashboard: nocilDashboardReducer,
    dataVisualization: dataVisualizationReducer,
    manualEntry: manualEntryReducer,
    calendarConfigurator: calendarConfiguratorReducer,
    operatorEntry: operatorEntryReducer,
    dataExplorer: dashboardReportingReducers,
    configure: configureReducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);
