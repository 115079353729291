import React, { useEffect, useState } from 'react';
import './index.scss';
import { Col, Row } from 'antd';
import CountAnalytics from 'components/common/CountAnalytics';
import TabsComponent from 'components/common/TabsComponent';
import ScreenNameHeading from 'components/common/ScreenNameHeading';
import { useNavigate, useParams } from 'react-router-dom';
import AttributeDefinition from './AttributeDefinition';
import { useDispatch, useSelector } from 'react-redux';
import { ATTRIBUTETYPE } from 'types/enums';
import CreateAttribute from './AttributeDefinition/CreateAttribute';
import EditAttribute from './AttributeDefinition/EditAttribute';
import ViewAttribute from './AttributeDefinition/ViewAttribute/Index';
import CreateUom from './AttributeDefinition/CreateUom';
import { ReactComponent as ActiveUserIcon } from 'assets/icons/activeUserCountIcon.svg';
import { ReactComponent as DeactivateUserIcon } from 'assets/icons/deacticeUserCountIcon.svg';
import { setAttributeState } from 'redux/actions/ConfigureActions/attributeActions';
import { hasTabPermission } from 'utils/commonFunction';

const Configure: React.FC<{ activate: any }> = ({ activate }) => {
    const { currentTab = 'attribute' } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeTabKey, setActiveTabKey] = useState(currentTab);
    const [isUomOpen, setIsUomOpen] = useState<any>(false);
    useEffect(() => {
        setActiveTabKey(currentTab);
    }, [currentTab]);

    useEffect(() => {
        navigate(`/configure/data-dictionary/${activeTabKey}`, {
            replace: true,
        });
    }, [activeTabKey]);

    const attributeState = useSelector(
        (state: any) => state?.configure?.attributes?.attributeState
    );

    const attributeData = useSelector(
        (state: any) => state.configure?.attributes?.attributesList
    );

    const allowedTabList = useSelector(
        (state: any) => state.root.allowedMenuList
    );

    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );

    useEffect(() => {
        dispatch(setAttributeState(ATTRIBUTETYPE.display));
    }, []);

    const tabItems = [
        {
            id: 1,
            permissionKey: 'Attribute',
            key: 'attribute',
            label: `Attribute`,
            children: isUomOpen ? (
                <CreateUom setIsUomOpen={setIsUomOpen} />
            ) : attributeState === ATTRIBUTETYPE.display ? (
                <AttributeDefinition />
            ) : attributeState === ATTRIBUTETYPE.create ? (
                <CreateAttribute setIsUomOpen={setIsUomOpen} />
            ) : attributeState === ATTRIBUTETYPE.edit ? (
                <EditAttribute setIsUomOpen={setIsUomOpen} />
            ) : attributeState === ATTRIBUTETYPE.view ? (
                <ViewAttribute setIsUomOpen={setIsUomOpen} />
            ) : (
                ''
            ),
        },
        {
            id: 2,
            permissionKey: 'Time Capsule',
            key: 'time-capsule',
            label: `Time Capsule`,
            children: '',
        },
        {
            id: 3,
            permissionKey: 'Table',
            key: 'table',
            label: `Table`,
            children: '',
        },
        {
            id: 4,
            permissionKey: 'Alert',
            key: 'alert ',
            label: `Alert `,
            children: '',
        },
    ];

    const getCountAnalyticsDetails = (): any => {
        switch (activeTabKey) {
            case 'table':
                return [
                    {
                        title: 'Active Table',
                        count: '0',
                        icon: <ActiveUserIcon />,
                    },
                    {
                        title: 'Inactive Table',
                        count: '0',
                        icon: <DeactivateUserIcon />,
                    },
                ];
            case 'alert':
                return [
                    {
                        title: 'Active Alert',
                        count: '0',
                        icon: <ActiveUserIcon />,
                    },
                    {
                        title: 'Inactive Alert',
                        count: '0',
                        icon: <DeactivateUserIcon />,
                    },
                ];
            case 'time-capsule':
                return [
                    {
                        title: 'Active Time Capsule',
                        count: '0',
                        icon: <ActiveUserIcon />,
                    },
                    {
                        title: 'Inactive Time Capsule',
                        count: '0',
                        icon: <DeactivateUserIcon />,
                    },
                ];
            default:
                return [
                    {
                        title: 'Active Attributes',
                        count: attributeData?.totalActiveAttribute
                            ? `${attributeData?.totalActiveAttribute}`
                            : '0',
                        icon: <ActiveUserIcon />,
                    },
                    {
                        title: 'Inactive Attributes',
                        count: attributeData?.totalDeactivateAttribute
                            ? `${attributeData?.totalDeactivateAttribute}`
                            : '0',
                        icon: <DeactivateUserIcon />,
                    },
                ];
        }
    };

    return (
        <>
            <div className="configure">
                <Row className="configure__headerWrapper">
                    <Col span={16}>
                        <ScreenNameHeading
                            heading={'Data Dictionary'}
                            subHeading={
                                'You can add, edit, or delete users or can change their roles'
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <CountAnalytics
                            countDetails={getCountAnalyticsDetails()}
                        />
                    </Col>
                </Row>
                <Row className="configure__tabswrapper fw-500 fs-16">
                    <Col span={24}>
                        <TabsComponent
                            tabItem={
                                loggedInUserDetails?.admin
                                    ? tabItems
                                    : hasTabPermission(tabItems, allowedTabList)
                            }
                            setTabKey={activate}
                            setActiveTabKey={setActiveTabKey}
                            activeTabKey={activeTabKey}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Configure;
